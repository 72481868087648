import React from 'react'

const Instagram = ({ fill = '#293337', color = '#FFFF', ...rest }) => (
  <svg {...rest} viewBox='0 0 24 25' fill="none" width="24" height="25" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.600098" width="24" height="24" rx="12" fill={fill} />
    <g clipPath="url(#clip0_1186_1941)">
    <path d="M14.7717 18.5909H9.23752C8.81358 18.5921 8.39355 18.5098 8.00141 18.3487C7.60927 18.1876 7.25271 17.9508 6.95207 17.6519C6.65144 17.3529 6.41263 16.9977 6.24928 16.6065C6.08592 16.2153 6.00122 15.7958 6.00001 15.3718V9.83761C5.9988 9.41289 6.08141 8.9921 6.2431 8.59936C6.40479 8.20662 6.64239 7.84965 6.94229 7.5489C7.24218 7.24815 7.59848 7.00953 7.99075 6.84672C8.38303 6.6839 8.80358 6.6001 9.2283 6.6001H14.7625C15.6187 6.6001 16.4398 6.94022 17.0452 7.54564C17.6507 8.15106 17.9908 8.97219 17.9908 9.82838V15.3626C17.9908 16.2172 17.6519 17.0369 17.0485 17.6421C16.4451 18.2472 15.6263 18.5884 14.7717 18.5909ZM9.2283 7.52247C8.61673 7.52247 8.03021 7.76541 7.59777 8.19785C7.16532 8.6303 6.92238 9.21682 6.92238 9.82838V15.3626C6.92238 15.6654 6.98202 15.9653 7.09791 16.245C7.21379 16.5248 7.38364 16.779 7.59777 16.9931C8.03021 17.4256 8.61673 17.6685 9.2283 17.6685H14.7625C15.3725 17.6685 15.9576 17.4268 16.3898 16.9964C16.822 16.5659 17.066 15.9818 17.0684 15.3718V9.83761C17.0696 9.53479 17.0112 9.2347 16.8964 8.95447C16.7817 8.67423 16.6128 8.41935 16.3996 8.20437C16.1863 7.98939 15.9328 7.81852 15.6535 7.70152C15.3742 7.58452 15.0745 7.52367 14.7717 7.52247H9.2283Z" fill={color}/>
    <path d="M15.0483 10.2251C14.8698 10.2251 14.6985 10.1541 14.5722 10.0279C14.4459 9.90159 14.375 9.73032 14.375 9.55175C14.375 9.37317 14.4459 9.2019 14.5722 9.07563C14.6985 8.94936 14.8698 8.87842 15.0483 8.87842C15.1363 8.87941 15.2231 8.89786 15.3039 8.93269C15.3846 8.96753 15.4577 9.01805 15.5187 9.08134C15.5808 9.14286 15.6299 9.21618 15.6631 9.29697C15.6964 9.37775 15.7132 9.46438 15.7124 9.55175C15.7124 9.72874 15.6428 9.89862 15.5185 10.0246C15.3942 10.1506 15.2253 10.2226 15.0483 10.2251ZM15.0483 9.30271C14.9985 9.30118 14.9494 9.31462 14.9073 9.34129C14.8653 9.36796 14.8322 9.40664 14.8123 9.45233C14.7924 9.49802 14.7867 9.54861 14.7959 9.59758C14.8051 9.64654 14.8288 9.69162 14.8639 9.727C14.8998 9.76036 14.9445 9.78278 14.9928 9.79165C15.041 9.80053 15.0908 9.79547 15.1363 9.77709C15.1818 9.7587 15.2211 9.72775 15.2496 9.68784C15.2781 9.64793 15.2947 9.60073 15.2974 9.55175C15.2974 9.4857 15.2711 9.42235 15.2244 9.37565C15.1777 9.32894 15.1144 9.30271 15.0483 9.30271Z" fill={color}/>
    <path d="M11.9953 15.2701C11.5554 15.2703 11.1221 15.162 10.7341 14.9547C10.346 14.7474 10.015 14.4476 9.77049 14.0819C9.52597 13.7161 9.37545 13.2957 9.33226 12.8579C9.28907 12.42 9.35454 11.9783 9.52289 11.5718C9.69123 11.1653 9.95724 10.8066 10.2973 10.5275C10.6374 10.2484 11.0411 10.0575 11.4727 9.97175C11.9042 9.88596 12.3502 9.90795 12.7712 10.0358C13.1922 10.1636 13.5752 10.3932 13.8862 10.7044C14.2769 11.0763 14.5461 11.5577 14.6584 12.0853C14.7706 12.6129 14.7207 13.1622 14.5152 13.6609C14.3098 14.1597 13.9583 14.5847 13.5069 14.8801C13.0555 15.1755 12.5254 15.3275 11.9861 15.3163L11.9953 15.2701ZM11.9953 10.8428C11.5877 10.8425 11.1927 10.9836 10.8774 11.242C10.5622 11.5004 10.3463 11.8601 10.2666 12.2598C10.1868 12.6596 10.2482 13.0746 10.4401 13.4341C10.6321 13.7937 10.9428 14.0756 11.3193 14.2318C11.6957 14.3879 12.1147 14.4087 12.5048 14.2906C12.8949 14.1725 13.232 13.9227 13.4586 13.5839C13.6852 13.2451 13.7874 12.8383 13.7476 12.4326C13.7078 12.027 13.5286 11.6476 13.2405 11.3593C12.9104 11.0289 12.4625 10.8431 11.9953 10.8428Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_1186_1941">
    <rect width="12" height="12" fill={color} transform="translate(6 6.6001)"/>
    </clipPath>
    </defs>
  </svg>
)

export default Instagram
