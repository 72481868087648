import { parseDate } from '../utils'

const APP_URL = process.env.REACT_APP_URL
const ORIGIN = process.env.REACT_APP_ORIGIN

function getLink (cuid) {
  if (!cuid) return undefined

  return `${APP_URL}/${cuid}`
}

export function serializeParams ({ dateOfBirth, knowledgeFinancialInvestments, gender, ...values }) {
  return {
    ...values,
    dateOfBirth: parseDate(dateOfBirth),
    knowledgeFinancialInvestments: Object.keys(knowledgeFinancialInvestments).filter((key) => knowledgeFinancialInvestments[key]),
    gender: gender === 'NOT_DECLARE' ? undefined : gender
  }
}

export function getPipzParams ({ cuid, payload, result }) {
  return {
    user: {
      birthDate: payload.dateOfBirth,
      name: payload.name,
      email: payload.email,
      phone: payload.phone
    },
    payload: {
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      nascimento: payload.dateOfBirth,
      genero: payload.gender,
      descricao_amigo: payload.behaviorDescription,
      situacao_atual: payload.financialSituation,
      perfil_risco: payload.riskOpinion,
      perfil_considerado: payload.riskTolerance,
      ocupacao: payload.mainOccupation,
      tempo_no_emprego: payload.timeOnCurrentWorkingBusiness,
      idade_renda: payload.ageToStopWorking,
      estado_civil: payload.civilStatus,
      dependentes: payload.dependents,
      patrimonio_atual: payload.investments,
      investimentos_12_meses: payload.investmentLast12Months,
      patrimonio_total_cripto: payload.investmentsInCrypto,
      renda_mensal: payload.personalIncome,
      renda_mensal_conjuge: payload.spouseIncome,
      renda_outras: payload.otherIncome,
      renda_futura: payload.expectation,
      gastos_mensais: payload.expenses,
      conhecimento_investimentos: getFinancialKnowledge(payload.knowledgeFinancialInvestments),
      valor_total_bens_imoveis_pessoais: payload.personalProperties,
      valor_total_bens_imoveis_investidos: payload.investmentProperties,
      valor_total_bens_moveis: payload.assets,
      outros_ativos: payload.otherAssets,
      obrigacoes: payload.liabilities,
      ...(
        result
          ? {
              tolerancia: result.riskProfile.bioFeedBacks.profile.type,
              bio_resultado: result.riskProfile.bioFeedBacks.profile.bioColor,
              reserva_necessaria: result.securityReserve.reserve,
              meses_cobertura: result.securityReserve.months
            }
          : {}
      ),
      link: getLink(cuid),
      origem: ORIGIN
    }
  }
}

export function getHubspotParams ({ cuid, payload, result }) {
  const [firstname, ...lastname] = payload.name.split(' ')
  const searchParams = new URLSearchParams(window.location.search)

  return {
    user: {
      date_of_birth: payload.dateOfBirth,
      firstname,
      lastname: lastname.join(' '),
      email: payload.email,
      phone: payload.phone
    },
    payload: {
      genero: payload.gender,
      descricao_amigo: payload.behaviorDescription,
      situacao_atual: payload.financialSituation,
      perfil_risco: payload.riskOpinion,
      perfil_considerado: payload.riskTolerance,
      ocupacao: payload.mainOccupation,
      tempo_no_emprego: payload.timeOnCurrentWorkingBusiness,
      idade_renda: payload.ageToStopWorking,
      marital_status: payload.civilStatus,
      dependentes: payload.dependents,
      patrimonio_atual: payload.investments,
      investimentos_12_meses: payload.investmentLast12Months,
      patrimonio_total_cripto: payload.investmentsInCrypto,
      renda_mensal: payload.personalIncome,
      renda_mensal_conjuge: payload.spouseIncome,
      renda_outras: payload.otherIncome,
      renda_futura: payload.expectation,
      gastos_mensais: payload.expenses,
      conhecimento_investimentos: payload.knowledgeFinancialInvestments.join(';'),
      valor_total_bens_imoveis_pessoais: payload.personalProperties,
      valor_total_bens_imoveis_investidos: payload.investmentProperties,
      valor_total_bens_moveis: payload.assets,
      outros_ativos: payload.otherAssets,
      obrigacoes: payload.liabilities,
      ...(
        result
          ? {
              tolerancia: result.riskProfile.bioFeedBacks.profile.type,
              bio_resultado: result.riskProfile.bioFeedBacks.profile.bioColor,
              reserva_necessaria: result.securityReserve.reserve,
              meses_cobertura: result.securityReserve.months
            }
          : {}
      ),
      link: getLink(cuid),
      origem: ORIGIN,
      hs_page_path: window.location.pathname,
      hs_page_title: window.location.title || '',
      hs_page_url: window.location.href,
      hs_utm_campaign: searchParams.get('utm_campaign') || '',
      hs_utm_medium: searchParams.get('utm_medium') || '',
      hs_utm_source: searchParams.get('utm_source') || ''
    }
  }
}

export function setInsiderUserObject (userData) {
  const { email, phone, name } = userData
  const [firstname, ...surname] = name.split(' ')

  window.insider_object = window.insider_object || {}
  window.insider_object.user = {
    email: email,
    phone_number: phone,
    name: firstname,
    surname: surname.join(' '),
    gdpr_optin: true,
    email_optin: true,
    sms_optin: true,
    whatsapp_optin: true,
  }

  if (window.Insider) {
    window.Insider.eventManager.dispatch('init-manager:re-initialize')
  }
}

export function sendInsiderTrackingEvent (eventName, bioResult) {
  const events = [
    {
      event_name: eventName,
      timestamp: new Date().toISOString().split('.')[0] + 'Z',
      ...bioResult && {
        event_params: {
          custom: {
            bio_resultado: bioResult.result.riskProfile.bioFeedBacks.profile.bioColor,
            bio_id: bioResult.cuid,
          }
        }
      }
    }
  ]
  if (window.Insider) {
    window.Insider.track('events', events)
  }
}

export function getFinancialKnowledge (data) {
  if (data.includes('NONE')) {
    return ['Nenhuma aplicação']
  }

  const arr = []

  if (data.includes('SAVINGS')) {
    arr.push('Poupança / previdência privada')
  }
  if (data.includes('CDB')) {
    arr.push('CDB / LCI / LCA')
  }
  if (data.includes('FIXED_INCOME')) {
    arr.push('Fundos de Investimento em Renda Fixa / DI de bancos')
  }
  if (data.includes('MULTIMARKET')) {
    arr.push('Fundos de Investimento Multimercado')
  }
  if (data.includes('STOCK')) {
    arr.push('Ações e fundos de ações, debêntures')
  }
  if (data.includes('BACKED_TOKEN')) {
    arr.push('Tokens lastreados (precatórios, direitos, consórcio, fluxo de pagamento)')
  }
  if (data.includes('CRYPTO')) {
    arr.push('Criptomoedas (Bitcoin, Ethereum, etc) e/ou fundos de criptomoedas')
  }

  return arr
}
