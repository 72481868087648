import React from 'react'
import QuestionMark from '../../components/Icons/QuestionMark'

// TITLES
export const FINANCIAL_PLAN = 'Seu planejamento financeiro'
export const EMERGENCY_RESERVE = 'Reserva de emergência'
export const RETIREMENT_PLAN = 'Plano de aposentadoria'
export const REAL_RETIREMENT_PLAN = <b>Plano de aposentadoria com base na realidade atual</b>

// TEXTS
export const BASED_ON_YOUR_ANSWERS = (bioColor) => <>Com base nas suas respostas e levando em consideração sua BIO Financeira <b>{bioColor}</b>, apresentamos a seguir um resumo do seu Planejamento Financeiro Pessoal.</>
export const RECOMMENDED_SECURITY_RESERVE = (value) => <>O valor recomendado para sua reserva de emergência é de <b>{value}</b>.</>
export const MONTHS_SECURITY_RESERVE = (months) => <>Suficiente para cobrir <b>{months}</b> meses das despesas.</>
export const INVESTED_IN = 'Investido em produtos de alta liquidez e baixo risco.'
export const INCOME_BY_CURRENT_ASSETS = <b>Renda gerada pelo patrimônio atual</b>
export const ASSETS_TO_GENERATE_INCOME = (value) => <>Você possui <b>{value}</b> em bens que podem gerar renda hoje ou no futuro, como os investimentos, imóveis e direitos a receber.</>
export const YEARS_TO_ACCUMULATE = (age, retirementAge, yearsUntilRetirement, yearsAfterRetirement, lifeExpectancy) => <>Além disso, você está com {age} anos e pretende se aposentar aos {retirementAge}. Ou seja, possui <b>{yearsUntilRetirement} anos para acumular patrimônio</b> e, considerando uma expectativa de vida de <b>{lifeExpectancy} anos</b>, precisará ter renda por mais <b>{yearsAfterRetirement} anos</b> após a aposentadoria.</>
export const BY_APPLYING = (assetsToGenerateIncome, monthlyRealRate, Tooltip) => <>Então, se você aplicar o valor de <b>{assetsToGenerateIncome}</b> em investimentos que tenham um rendimento de <Tooltip><>{monthlyRealRate} ao mês <QuestionMark size={16} /></></Tooltip> acima da inflação e líquido de impostos, você:</>
export const MONTHLY_INCOME = (lifeExpectancy) => <>De hoje até os seus {lifeExpectancy} anos, teria uma renda mensal de:</>
export const CAN_EXPEND = (expenses) => <>Poderia gastar os mesmos {expenses} atuais de hoje até os seus:</>
export const YEARS = 'anos'
export const BIO_RATE = (bioColor, rate) => <>Considerando sua BIO Financeira <b>{bioColor}</b>, é possível buscar um rendimento de {rate} ao ano acima da inflação nos seus investimentos.</>
export const BY_BIO_APPLYING = (bioColor, assetsToGenerateIncome) => <>Se aplicar o valor de <b>{assetsToGenerateIncome}</b> em investimentos adequados à sua BIO <b>{bioColor}</b>, você:</>
export const RETIREMENT_INCOME_DESIRED = (expenses, retirementAge, lifeExpectancy) => <>Para ter uma renda de aposentadoria de {expenses} dos {retirementAge} aos {lifeExpectancy} anos, precisará investir mensalmente um valor de:</>
export const SAVINGS_INVESTMENT = (savingsCapacity, age, retirementAge, lifeExpectancy) => <>Caso invista o valor da sua capacidade de poupança atual ({savingsCapacity} por mês) dos {age} aos {retirementAge} anos, você terá dos {retirementAge} aos {lifeExpectancy} anos uma renda mensal de:</>
export const TOOLTIP = 'Considerando a rentabilidade esperada para carteira de investimentos bem diversificada em Renda Fixa Digital, fundos imobiliários, boas ações pagadoras de dividendos e títulos públicos federais do tipo Tesouro IPCA+.'
export const ALREADY_HAVE_RETIREMENT_INCOME = (expenses, retirementAge, lifeExpectancy) => <>Já consegue ter uma renda de aposentadoria dos {retirementAge} aos {lifeExpectancy} anos no valor de {expenses}, equivalente às suas despesas atuais.</>
export const CAN_EXPEND_ALL = (expenses) => <>Poderia gastar os mesmos {expenses} atuais até o fim de sua vida.</>
export const NO_SAVINGS = 'Como você não possui capacidade de poupança, você não consegue ter uma renda mensal para a aposentadoria na sua realidade atual.'
