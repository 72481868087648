import React, { useMemo } from 'react'
import dictionary from '../../core/dictionary'
import { decimalToPercent, currencyFormat } from '../../core/utils'

import { useTheme } from 'styled-components'
import { ResponsivePie } from '@nivo/pie'

import {
  Wrapper,
  ChartContainer,
  LegendsContainer,
  Item,
  Color,
  Text,
  ChartOverlay
} from './styles'
import { Typography } from '@material-ui/core'

const WalletPieChart = ({ data, totalAssets }) => {
  const theme = useTheme()
  const formattedData = useMemo(() => data.map(({ label, value, color }) => ({
    id: label,
    color,
    value
  })), [data])

  formattedData.sort((a, b) => b.value - a.value)

  const chartTheme = {
    fontFamily: theme.typography.subtitle1.fontFamily,
    fontSize: 14
  }
  const margin = { top: 20, bottom: 20, left: 100, right: 100 }
  return (
    <Wrapper>
      <ChartContainer>
        <ResponsivePie
          data={formattedData.filter(({ value }) => value > 0)}
          theme={chartTheme}
          colors={({ data }) => data.color}
          innerRadius={0.75}
          margin={margin}
          arcLinkLabel={({ value }) => decimalToPercent(value / totalAssets)}
          arcLinkLabelsTextColor={theme.colors.darker}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsThickness={2}
          isInteractive={false}
          enableArcLabels={false}
          sortByValue
          arcLinkLabelsSkipAngle={15}
        />
        <ChartOverlay $margin={margin}>
          <Typography variant='caption'>
            {dictionary.result.financesAndAssets.TOTAL}
          </Typography>
          <Typography variant='caption'>
            {currencyFormat(totalAssets)}
          </Typography>
        </ChartOverlay>
      </ChartContainer>
      <LegendsContainer>
        {formattedData.map(({ value, id, color }) => (
            <Item key={id}>
              <Color $color={color}/>
              <div style={{ marginLeft: 8 }}>
                <Text>{`${id} (${decimalToPercent(value / totalAssets)})`}</Text>
                <Text>{currencyFormat(value)}</Text>
              </div>
            </Item>
        ))}
      </LegendsContainer>
    </Wrapper>
  )
}

export default WalletPieChart
