import React from 'react'

const Facebook = ({ fill = '#293337', color = '#FFFF', ...rest }) => (
  <svg {...rest} width="24" height="25" viewBox='0 0 24 25' fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.600098" width="24" height="24" rx="12" fill={fill} />
    <g clipPath="url(#clip0_1186_1934)">
    <path d="M14.7735 18.6186H9.22652C8.80159 18.6174 8.38107 18.5325 7.98895 18.3688C7.59684 18.205 7.24081 17.9657 6.9412 17.6643C6.64159 17.363 6.40427 17.0056 6.24278 16.6126C6.08129 16.2195 5.9988 15.7985 6.00001 15.3736V9.82661C6.00245 8.97164 6.34317 8.15238 6.94773 7.54783C7.55229 6.94327 8.37154 6.60255 9.22652 6.60011H14.7735C15.1984 6.5989 15.6194 6.68139 16.0125 6.84288C16.4055 7.00437 16.7629 7.24169 17.0642 7.5413C17.3656 7.84091 17.6049 8.19693 17.7687 8.58905C17.9324 8.98116 18.0173 9.40169 18.0185 9.82661V15.3736C18.0197 15.8001 17.9366 16.2226 17.774 16.6169C17.6113 17.0111 17.3724 17.3693 17.0708 17.6709C16.7692 17.9725 16.411 18.2114 16.0168 18.3741C15.6225 18.5367 15.2 18.6198 14.7735 18.6186ZM9.22652 7.52461C8.61674 7.52704 8.03263 7.77036 7.60144 8.20154C7.17026 8.63272 6.92695 9.21683 6.92451 9.82661V15.3736C6.9233 15.6771 6.98187 15.9779 7.0969 16.2588C7.21193 16.5397 7.38116 16.7951 7.59492 17.0106C7.80868 17.2261 8.06279 17.3974 8.34274 17.5146C8.62269 17.6319 8.923 17.6929 9.22652 17.6941H14.7735C15.0786 17.6953 15.3809 17.6361 15.663 17.52C15.9451 17.4038 16.2014 17.2329 16.4171 17.0172C16.6328 16.8014 16.8037 16.5452 16.9199 16.2631C17.036 15.981 17.0952 15.6787 17.094 15.3736V9.82661C17.0928 9.5231 17.0318 9.22279 16.9145 8.94284C16.7973 8.66289 16.626 8.40878 16.4105 8.19502C16.195 7.98126 15.9396 7.81203 15.6587 7.697C15.3778 7.58197 15.077 7.52339 14.7735 7.52461H9.22652Z" fill={color} />
    <path d="M14.7641 13.6169H11.4452C11.3226 13.6169 11.205 13.5682 11.1183 13.4815C11.0316 13.3948 10.9829 13.2772 10.9829 13.1546C10.9829 13.032 11.0316 12.9145 11.1183 12.8278C11.205 12.7411 11.3226 12.6924 11.4452 12.6924H14.7641C14.8867 12.6924 15.0043 12.7411 15.091 12.8278C15.1777 12.9145 15.2264 13.032 15.2264 13.1546C15.2264 13.2772 15.1777 13.3948 15.091 13.4815C15.0043 13.5682 14.8867 13.6169 14.7641 13.6169Z" fill={color} />
    <path d="M12.5545 18.6185C12.4319 18.6185 12.3144 18.5698 12.2277 18.4831C12.141 18.3964 12.0923 18.2788 12.0923 18.1562V12.0176C12.0923 11.4626 12.3121 10.9302 12.7037 10.5369C13.0953 10.1436 13.6267 9.92139 14.1817 9.91895H14.7641C14.8867 9.91895 15.0043 9.96765 15.0909 10.0543C15.1776 10.141 15.2263 10.2586 15.2263 10.3812C15.2263 10.5038 15.1776 10.6214 15.0909 10.7081C15.0043 10.7947 14.8867 10.8434 14.7641 10.8434H14.1817C14.0279 10.8434 13.8757 10.8739 13.7337 10.933C13.5918 10.9921 13.463 11.0787 13.3547 11.1879C13.2464 11.297 13.1608 11.4265 13.1028 11.5689C13.0448 11.7113 13.0156 11.8638 13.0168 12.0176V18.1377C13.018 18.1997 13.007 18.2612 12.9845 18.3189C12.9619 18.3766 12.9282 18.4292 12.8853 18.4739C12.8423 18.5185 12.791 18.5543 12.7343 18.5791C12.6775 18.6039 12.6165 18.6173 12.5545 18.6185Z" fill={color} />
    </g>
    <defs>
    <clipPath id="clip0_1186_1934">
    <rect width="12" height="12" fill={color} transform="translate(6 6.6001)"/>
    </clipPath>
    </defs>
  </svg>
)

export default Facebook
