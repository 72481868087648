import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    height: 100%;
    position: ${({ position }) => position};
    right: 0;
    width: ${({ width }) => width};
    pointer-events: none;
`
