import React, { createRef, useCallback, useState } from 'react'
import { Formik, Form } from 'formik'

import dictionary from '../../../core/dictionary'
import useResizeListener from '../../../core/hooks/useResizeListener'
import validationSchema from './validation.schema'

import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import InputMaskedCurrency from '../../../components/Form/InputMaskedCurrency'
import ButtonsContainer from '../../../components/ButtonsContainer'
import Checkbox from '../../../components/Form/Checkbox'

const BioStep4 = ({ formValues, onContinue, dict }) => {
  const investmentKnowledgeOptions = dict.client.financial.knowledgeFinancialInvestments.options
  const [investmentLast12MonthsLabelSize, setInvestmentLast12MonthsLabelSize] = useState(16)
  const investmentLast12MonthsRef = createRef()

  const handleResize = useCallback(() => {
    if (investmentLast12MonthsRef.current) {
      setInvestmentLast12MonthsLabelSize(investmentLast12MonthsRef.current.firstChild.clientHeight)
    }
  }, [investmentLast12MonthsRef])

  useResizeListener(handleResize)

  const onSubmit = (values) => {
    onContinue(values)
  }

  const validateKnowledge = (values) => {
    const isValid = investmentKnowledgeOptions.find((v) => values.knowledgeFinancialInvestments[v.value])

    if (!isValid) {
      return { knowledge: dictionary.validation.REQUIRED_FIELD }
    }

    return {}
  }

  return (
    <Formik
      validateOnBlur
      initialValues={{ ...formValues, knowledge: '' }}
      onSubmit={onSubmit}
      validate={validateKnowledge}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="investment_expertise"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='investmentLast12Months'
            name='investmentLast12Months'
            select
            value={values.investmentLast12Months}
            label={dict.client.financial.investmentLast12Months.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.investmentLast12Months && errors.investmentLast12Months)}
            helperText={touched.investmentLast12Months ? errors.investmentLast12Months : ''}
            fullWidth
            margin='normal'
            ref={investmentLast12MonthsRef}
            InputLabelProps={{
              style: { top: investmentLast12MonthsLabelSize > 16 ? -16 : 0 }
            }}
          >
            {dict.client.financial.investmentLast12Months.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='investments'
            name='investments'
            type='tel'
            value={values.investments}
            label={dict.client.financial.investments.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.investments && errors.investments)}
            helperText={(touched.investments && errors.investments) ? errors.investments : dict.client.financial.investments.helperText}
            margin='normal'
            fullWidth
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='investmentsInCrypto'
            name='investmentsInCrypto'
            type='tel'
            value={values.investmentsInCrypto}
            label={dict.client.financial.investmentsInCrypto.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.investmentsInCrypto && errors.investmentsInCrypto)}
            helperText={(touched.investmentsInCrypto && errors.investmentsInCrypto) ? errors.investmentsInCrypto : dict.client.financial.investmentsInCrypto.helperText}
            margin='normal'
            fullWidth
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <FormControl style={{ marginTop: 32 }} margin='normal' error={Boolean(touched.knowledge && errors.knowledge)}>
            <FormLabel shrink>
              {dict.client.financial.knowledgeFinancialInvestments.bioLabel}
            </FormLabel>
            <FormGroup style={{ paddingTop: 16 }}>
              {investmentKnowledgeOptions.map(({ value, label }) => (
                <Checkbox
                  key={`knowledgeFinancialInvestments[${value}]`}
                  id={`knowledgeFinancialInvestments[${value}]`}
                  checked={values.knowledgeFinancialInvestments[value]}
                  label={label}
                  onChange={(e) => {
                    if (value === 'NONE') {
                      investmentKnowledgeOptions.filter((option) => option.value !== 'NONE')
                        .forEach((v) => setFieldValue(`knowledgeFinancialInvestments[${v.value}]`, false))
                    } else {
                      setFieldValue('knowledgeFinancialInvestments.NONE', false)
                    }

                    handleChange(e)
                  }}
                />
              ))}
            </FormGroup>
            {touched.knowledge && errors.knowledge && (
              <FormHelperText>{errors.knowledge}</FormHelperText>
            )}
          </FormControl>
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep4
