import styled from 'styled-components'
import { Button as MuiButton, Typography } from '@material-ui/core'
import CustomCheck from '../../components/Icons/Check'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ItemWrapper = styled.div`
  margin: 16px 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: initial;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const NameContainer = styled.div`
  margin-bottom: 24px;
`

export const TitleContainer = styled.div`
  margin-top: 64px;
  margin-bottom: 24px;
`

export const TextContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: initial;
  }
`

export const Item = styled.div`
  display: flex;
  margin-bottom: 8px;
`

export const TextPrimaryH3 = styled(Typography).attrs({ variant: 'h3', color: 'primary' })`
  text-transform: capitalize;
`

export const Title = styled(Typography).attrs({ variant: 'h4' })``

export const Text = styled(Typography).attrs({ variant: 'body1' })``

export const TextPrimary = styled(Typography).attrs({ variant: 'h3', color: 'primary' })``

export const TextBasicGray = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.neutral40};
`

export const TextMutedGray = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.neutral40};
`

export const TextCTA = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.neutral40};
  margin-top: 24px;
`

export const TextMutedGrayBold = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.neutral40};
  font-weight: 700;
`

export const TextRed = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.palette.error.main};
`

export const BulletContainer = styled(Typography).attrs({
  variant: 'h6'
})`
  margin-bottom: 24px;
`

export const BulletText = styled.li`
  list-style: inside;
`

export const Button = styled(MuiButton).attrs({ variant: 'contained', color: 'primary', fullWidth: true })`
  margin-top: 64px;
  height: 40px;

  &:last-of-type {
    margin-bottom: 64px;
  }
`

export const Check = styled(CustomCheck)`
  width: 16px;
  fill: ${({ theme }) => theme.palette.primary.main};
  margin-right: 8px;
  stroke: transparent;
  filter: drop-shadow(0px 3px 6px #f4812040);
`

export const TooltipContent = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  white-space: nowrap;
  cursor: help;

  & > svg {
    fill: ${({ theme }) => theme.palette.primary.main};
    transform: translateY(2px);
  }
`

export const IconContainer = styled.div`
  width: 24px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0 4px;

  &:first-child {
    padding-top: initial;
  }

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral30}20`};
  };

  &:last-child {
    padding-bottom: initial;
  }
`

export const TextWithBoldPrimary = styled(Text)`
  > b: first-child {
    color: ${({ theme }) => theme.colors.primary30}
  }
`
