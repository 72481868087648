import { createTheme } from '@material-ui/core/styles'
import { styles } from './styles'

export const theme = createTheme({
  colors: {
    ...styles.colors
  },
  palette: {
    primary: {
      main: styles.colors.primary30,
      contrastText: styles.colors.white,
      light: styles.colors.primary10,
      dark: styles.colors.primary50
    },
    secondary: {
      main: styles.colors.neutral50,
      contrastText: styles.colors.primary30
    },
    warning: {
      main: styles.colors.warning30
    },
    error: {
      main: styles.colors.negative40
    },
    action: {
      selected: styles.colors.neutral10,
      hover: styles.colors.neutral5
    }
  },
  typography: {
    fontFamily: styles.fontFamily,
    fontSize: 16,
    h1: {
      ...styles.typographies.web.h3,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.h4
      }
    },
    h2: {
      ...styles.typographies.h3,
      '@media screen and (min-width:920px)': {
        ...styles.typographies.h2
      }
    },
    h3: {
      ...styles.typographies.web.h3,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.h3
      }
    },
    h4: {
      ...styles.typographies.web.h4,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.h4
      }
    },
    h6: {
      ...styles.typographies.web.h6,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.h6
      }
    },
    body1: {
      ...styles.typographies.web.body2,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.body2
      }
    },
    body2: {
      ...styles.typographies.web.body3,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.body3
      }
    },
    subtitle1: {
      ...styles.typographies.web.caption1,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.caption1
      }
    },
    caption: {
      ...styles.typographies.web.caption1,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.caption1
      }
    },
    button: {
      ...styles.typographies.web.button1,
      '@media screen and (max-width:920px)': {
        ...styles.typographies.mobile.button1
      }
    }
  },
  props: {
    MuiButton: {
      centerRipple: true
    }
  },
  overrides: {
    PrivateSwitchBase: {
      root: {
        padding: '0 8px'
      }
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 16
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: 'unset'
      }
    },
    MuiFormLabel: {
      root: {
        color: styles.colors.neutral40,
        '&$focused': {
          color: styles.colors.primary30
        }
      },
      shrink: {
        transform: 'translate(0, -2px)'
      }
    },
    MuiButton: {
      root: {
        height: 40,
        borderRadius: 3
      },
      containedPrimary: {
        '&:hover': {
          boxShadow: 'none'
        },
        boxShadow: 'none'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${styles.colors.primary30}`
        },
        '&:before': {
          borderBottom: `1px solid ${styles.colors.neutral50}4d`
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${styles.colors.primary30}`
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: styles.colors.neutral40
      }
    },
    MuiInputLabel: {
      root: {
        color: styles.colors.neutral40,
        '&$focused': {
          color: styles.colors.primary30
        }
      },
      shrink: {
        transform: 'translate(0, -2px)'
      }
    },
    MuiListItem: {
      root: {
        '&$focusVisible': {
          backgroundColor: styles.colors.primary10
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: styles.colors.primary30
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'normal'
      }
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal'
      }
    }
  }
})

export default theme
