import React from 'react'
import NumberFormat from 'react-number-format'

const InputNumber = ({ inputRef, onBlur, onChange, value, name, allowNegative = false, ...props }) => (
  <NumberFormat
    {...props}
    value={value}
    getInputRef={inputRef}
    onBlur={() => {
      if (onBlur) {
        onBlur({ target: { value, name } })
      }
    }}
    onValueChange={values => {
      onChange({
        target: {
          value: values.floatValue,
          name
        }
      })
    }}
    decimalScale={0}
    allowNegative={allowNegative}
  />
)

export default InputNumber
