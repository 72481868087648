import React from 'react'
import dictionary from '../../../core/dictionary'

import {
  BioStepperBorder,
  BioStepperContainer,
  LinksContainer,
  BioLink,
  StepDot,
  StepLabel,
  MobileText,
  LastStep
} from './styles'

const bioSteps = [
  {
    id: 1,
    name: dictionary.bio.KNOWING_YOU
  },
  {
    id: 2,
    name: dictionary.bio.BEHAVIORAL_PROFILE
  },
  {
    id: 3,
    name: dictionary.bio.LIFE_MOMENT
  },
  {
    id: 4,
    name: dictionary.bio.INVESTMENTS_EXPERIENCE
  },
  {
    id: 5,
    name: dictionary.bio.FINANCE
  },
  {
    id: 6,
    name: dictionary.bio.PATRIMONY
  },
  {
    id: 7,
    name: dictionary.bio.BIO_RESULT
  }
]

const BioStepper = ({ step, lastStep }) => {
  return (
    <BioStepperBorder>
      <BioStepperContainer>
        <LinksContainer $isLastStep={step === lastStep}>
          {bioSteps.map(({ id, name }) => {
            const active = id === step
            const disabled = id > step

            return (
              <BioLink key={id} active={active}>
                <StepDot $isLastStep={id === lastStep} active={active} disabled={disabled} />
                {id === lastStep && step === lastStep
                  ? <LastStep>{name}</LastStep>
                  : <StepLabel $isLastStep={id === lastStep && step === lastStep}>{name}</StepLabel>
                }
              </BioLink>
            )
          })}
        </LinksContainer>
        <MobileText $isLastStep={step === lastStep}>{bioSteps[step - 1].name}</MobileText>
      </BioStepperContainer>
    </BioStepperBorder>
  )
}

export default BioStepper
