import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const BioStepperBorder = styled.div`
  min-width: 400px;

  @media screen and (max-width: 920px) {
    min-width: unset;
  }
`

export const BioStepperContainer = styled.div`
  display: flex;
  padding: 32px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary5};
  border-radius: 10px;
  margin-left: 64px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    height: ${({ $isLastStep }) => $isLastStep ? '48px' : '70px'};
    padding: 8px;
    margin-left: 0px;
    margin-bottom: 32px;
    justify-content: center;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 920px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: unset;
    display: ${({ $isLastStep }) => $isLastStep ? 'none' : 'flex'};
  }
`

export const BioLink = styled.div`
  display: flex;
  align-items: center;
`

export const StepDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.primary30};
  opacity: ${({ disabled, active }) => (
    disabled
      ? 0.25
      : active
        ? 1
        : 0.25
  )};
  transition: background-color .5s linear;
  display: ${({ $isLastStep }) => $isLastStep ? 'none' : 'inline-block'};

  @media screen and (max-width: 920px) {
    width: ${({ active }) => active ? '32px' : '16px'};
    transition: background-color .5s linear, width .5s ease-in-out ${({ active }) => active && '.3s'};
  }
`

export const StepLabel = styled(Typography).attrs({
  variant: 'body1'
})`
 font-weight: ${({ $isLastStep }) => $isLastStep ? 900 : 'initial'};
  @media screen and (max-width: 920px) {
    display: none;
  }
`

export const LastStep = styled(Typography).attrs({
  variant: 'h6'
})`
  @media screen and (max-width: 920px) {
    display: none;
  }
`

export const MobileText = styled(Typography).attrs({
  variant: 'body2'
})`
  display: none;

  @media screen and (max-width: 920px) {
    display: block;
    text-align: center;
    font-weight: ${({ $isLastStep }) => $isLastStep ? 700 : 'initial'};
    font-size: ${({ $isLastStep }) => $isLastStep ? '16px' : 'initial'};
  }
`
