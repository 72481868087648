import React from 'react'
import dictionary from '../../../../../core/dictionary'
import { currencyFormat } from '../../../../../core/utils'

import ExpansionPanel from '../../../../../components/ExpansionPanel'

import { TextMutedGray, TextMutedGrayBold, TextRed, Row } from '../../../styles'

const Finances = ({ totalIncome, expenses, balance }) => {
  return (
    <ExpansionPanel title={dictionary.result.financesAndAssets.FINANCES} fixed>
      <Row>
        <TextMutedGray>{dictionary.result.financesAndAssets.INCOMES}</TextMutedGray>
        <TextMutedGray>{currencyFormat(totalIncome)}</TextMutedGray>
      </Row>
      <Row>
        <TextMutedGray>{dictionary.result.financesAndAssets.EXPENSES}</TextMutedGray>
        <TextRed>{currencyFormat(expenses)}</TextRed>
      </Row>
      <Row>
        <TextMutedGray>{dictionary.result.financesAndAssets.BALANCE_TO_INVEST}</TextMutedGray>
        <TextMutedGrayBold>{currencyFormat(balance)}</TextMutedGrayBold>
      </Row>
    </ExpansionPanel>
  )
}

export default Finances
