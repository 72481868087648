import React from 'react'
import dictionary from '../../../../../core/dictionary'
import { currencyFormat } from '../../../../../core/utils'

import ExpansionPanel from '../../../../../components/ExpansionPanel'

import { Text, Item, Check } from '../../../styles'

const CryptoSection = ({ savings }) => (
  <>
    <Text style={{ marginBottom: 24 }}>
      {dictionary.result.investments.CAN_SAVE(currencyFormat(savings.total))}
    </Text>
    <Item>
      <Check />
      <Text>{dictionary.result.investments.SAVE_TRADITIONAL(currencyFormat(savings.traditional))}</Text>
    </Item>
    <Item>
      <Check />
      <Text>{dictionary.result.investments.SAVE_CRYPTO(currencyFormat(savings.crypto))}</Text>
    </Item>
  </>
)

const NoCryptoSection = ({ bioColor, savings }) => (
  <>
    <Text style={{ marginBottom: 24 }}>
      {dictionary.result.investments.NEXT_STEPS_NO_CRYPTO(bioColor, currencyFormat(savings.total))}
    </Text>
    <Text>{dictionary.result.investments.NEXT_STEPS_CRYPTO}</Text>
  </>
)

const ReserveSection = ({ savings }) => (
  <>
    <Text style={{ marginBottom: 24 }}>
      {dictionary.result.investments.NEXT_STEPS_RESERVE(currencyFormat(savings.total))}
    </Text>
    <Text>{dictionary.result.investments.NEXT_STEPS_CRYPTO}</Text>
  </>
)

const NoSavingsSection = () => (
  <Text>{dictionary.result.investments.NO_SAVINGS}</Text>
)

const sectionMap = {
  CRYPTO: CryptoSection,
  NO_CRYPTO: NoCryptoSection,
  RESERVE: ReserveSection,
  NO_SAVINGS: NoSavingsSection
}

const getSectionComponent = (section, props) => {
  const Component = sectionMap[section]

  return <Component {...props} />
}

const NextStepsCard = ({ section, savings, bioColor }) => {
  const parsedSection = savings?.total <= 0 ? 'NO_SAVINGS' : section

  return (
    <ExpansionPanel title={dictionary.result.investments.NEW_CONTRIBUITIONS}>
      <>
        {getSectionComponent(parsedSection, { savings, bioColor })}
      </>
    </ExpansionPanel >
  )
}

export default NextStepsCard
