import React from 'react'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'

const Layout = ({ step, onBack, children, params }) => (
  <>
    <Header step={step} onBack={onBack} params={params} />
    <Content>
      {children}
    </Content>
    <Footer />
  </>
)

export default Layout
