import React from 'react'

export const BIO_DETAILS = 'Detalhes sobre sua BIO'
export const KNOWING_BIO_RESULTS = 'Conhecendo sua BIO'
export const LIFE_MOMENT = <b>Momento de vida</b>
export const BIO_RESULT = ({ firstName, bioColor, bioText }) => <>De acordo com a nossa metodologia, a BIO Financeira de {firstName} está <b>{bioColor}</b>. {bioText}</>
export const BEHAVIORAL_PROFILE = <b>Perfil comportamental</b>
export const WORK_EXPERIENCE = ({ timeOnCurrentWorkingBusiness, occupation }) => <>Está há {timeOnCurrentWorkingBusiness} atuando como {occupation}</>
export const PROFILE = ({ age, civilStatus }) => <>{age} anos, {civilStatus}</>
export const PERSPECTIVE = (expectation) => <>Tem perspectiva de {expectation} de renda mensal nos próximos anos.</>
export const JUSTIFICATION = 'Com base nas suas respostas, entendemos que:'
export const CONCLUSION = 'Nossa conclusão é que:'
export const YOUR_RISK_PROFILE_IS = 'Levando em conta os fatores comportamentais, seu perfil de risco é'
