import styled from 'styled-components'
import { Button, Typography } from '@material-ui/core'

export const Container = styled.div`
  padding: 16px 0;
`

export const Main = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 64px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  width: 450px;

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`

export const AgreementText = styled(Typography).attrs({
  variant: 'body1'
})`
  text-align: center;

  & > a {
    color: ${({ theme }) => theme.colors.neutral50};
  }

  @media screen and (max-width: 920px) {
    text-align: left;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
`

export const StartButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary'
})`
  min-width: 210px;

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`
