import * as yup from 'yup'
import dictionary from '../../../core/dictionary'

const validationSchema = yup.object().shape({
  personalIncome: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  otherIncome: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  spouseIncome: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  expectation: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  expenses: yup.number()
    .positive(dictionary.validation.POSITIVE_NUMBER_REQUIRED)
    .required(dictionary.validation.REQUIRED_FIELD)
})

export default validationSchema
