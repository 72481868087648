import React from 'react'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '../Icons/ExpandMore'
import { Panel, PanelHeader, PanelTitle } from './styles'

const ExpansionPanel = ({
  title,
  children,
  defaultExpanded = true,
  fixed = false,
  ...props
}) => {
  const panelProps = {
    ...props,
    defaultExpanded,
    ...(fixed && { expanded: true })
  }
  const headerProps = fixed
    ? {
        style: { cursor: 'default' }
      }
    : {
        expandIcon: <ExpandMoreIcon />,
        'aria-controls': `${title}-content`,
        id: `${title}-header`
      }

  return (
    <Panel {...panelProps}>
      <PanelHeader {...headerProps}><PanelTitle>{title}</PanelTitle></PanelHeader>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {children}
      </AccordionDetails>
    </Panel>
  )
}

export default ExpansionPanel
