import React from 'react'
import parse from 'html-react-parser'
import dictionary from '../../../../core/dictionary'
import { compact, optionsToReduce } from '../../../../core/utils'

import ExpansionPanel from '../../../../components/ExpansionPanel'
import Summary from './Summary'
import LifeMoment from './LifeMoment'
import BehavioralProfile from './BehavioralProfile'

const KnowingBio = ({ data: { payload, result }, dict }) => {
  const { name, age, civilStatus, mainOccupation, timeOnCurrentWorkingBusiness, expectation } = payload
  const { riskProfile } = result
  const { bioFeedBacks, bioResult } = riskProfile

  const firstName = name.split(' ')[0]
  const bioNumber = bioResult.bioColor
  const bioColor = bioFeedBacks.profile.bioColor
  const bioText = parse(bioFeedBacks.profile.htmlBioText)

  const civilStatusMap = optionsToReduce(dict.client.civilStatus.options)
  const mainOccupationMap = optionsToReduce(dict.client.professional.mainOccupation.options)
  const timeOnCurrentWorkingBusinessMap = optionsToReduce(dict.client.professional.timeOnCurrentWorkingBusiness.options)
  const expectationMap = optionsToReduce(dict.client.financial.expectation.options)

  const lifeMomentProps = {
    firstName,
    age,
    civilStatus: civilStatusMap[civilStatus].label.toLowerCase(),
    occupation: mainOccupationMap[mainOccupation].label.toLowerCase(),
    timeOnCurrentWorkingBusiness: timeOnCurrentWorkingBusinessMap[timeOnCurrentWorkingBusiness].label.toLowerCase(),
    expectation: expectationMap[expectation].labelResult,
    text: bioFeedBacks.lifeMoment.conclusionLifeMoment,
    justifications: compact([
      bioFeedBacks.lifeMoment.timeToRetire,
      bioFeedBacks.lifeMoment.hasDependents
    ])
  }

  const behavioralProfileProps = {
    firstName,
    profileType: bioFeedBacks.profile.type,
    profileJustification: bioFeedBacks.profile.justification,
    justifications: compact([
      bioFeedBacks.profile.A,
      bioFeedBacks.profile.B,
      bioFeedBacks.profile.C
    ])
  }

  return (
    <>
      <Summary
        firstName={firstName}
        fullName={name.toLowerCase()}
        bioColor={bioColor}
        bioNumber={bioNumber}
        bioText={bioText}
      />
      <ExpansionPanel title={dictionary.result.knowingBio.BIO_DETAILS}>
        <LifeMoment {...lifeMomentProps} />
        <BehavioralProfile {...behavioralProfileProps} />
      </ExpansionPanel>
    </>
  )
}

export default KnowingBio
