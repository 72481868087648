import React from 'react'

import worldCountries from 'world-countries'
import parsePhone from 'libphonenumber-js/min'
import styled from 'styled-components'

import { Select, MenuItem, TextField, InputLabel, FormHelperText } from '@material-ui/core'
import InputMaskedPhone from '../InputMaskedPhone'
import NumberString from '../NumberString'

const CountrySelect = styled(Select)`
  & .MuiSelect-selectMenu {
    white-space: nowrap;
  }
`

const NumberInput = styled(TextField)`
  min-height: unset;
`

const countries = worldCountries
  .filter(({ idd, flag }) => idd.root && flag)
  .map(({ flag, cca2, translations, idd }) => {
    const commonData = {
      flag,
      name: translations.por.common,
      cca2
    }

    // Santa Helena, Ascensão e Tristão da Cunha
    if (cca2 === 'SH') {
      return {
        ...commonData,
        countryCode: idd.root + idd.suffixes[0]
      }
    }

    // Saara Ocidental
    if (cca2 === 'EH') {
      return {
        ...commonData,
        countryCode: idd.root + 12
      }
    }

    // Cidade do Vaticano
    if (cca2 === 'VA') {
      return {
        ...commonData,
        countryCode: idd.root + idd.suffixes[1]
      }
    }

    // Qzaquistão, Rússia e USA
    if (['KZ', 'RU', 'US', 'PR', 'DO'].includes(cca2)) {
      return {
        ...commonData,
        countryCode: idd.root
      }
    }

    return {
      ...commonData,
      countryCode: idd.root + idd.suffixes
    }
  })
  .sort((a, b) => a.name.localeCompare(b.name, 'br', { ignorePunctuation: true }))

const PhoneInput = (props) => {
  const {
    id,
    name,
    label,
    error,
    helperText,
    value,
    onChange,
    onBlur = () => null,
    defaultCountry = 'BR',
    preferredCountries = ['BR', 'US'],
    InputProps,
    inputProps,
    containerStyle
  } = props

  const [phone, setPhone] = React.useState('')
  const [country, setCountry] = React.useState(defaultCountry)
  const filteredCountries = React.useMemo(() => {
    const { preferred, notPreferred } = countries.reduce((acc, country) => {
      if (preferredCountries.includes(country.cca2)) {
        acc.preferred.push(country)
      } else {
        acc.notPreferred.push(country)
      }

      return acc
    }, { preferred: [], notPreferred: [] })

    return [
      ...preferred,
      ...notPreferred
    ]
  }, [preferredCountries])
  const isBR = React.useMemo(() => country === 'BR', [country])

  React.useEffect(() => {
    if (value) {
      const parsedPhone = parsePhone(value)
      if (!parsedPhone) {
        return
      }

      const { nationalNumber, country: parsedCountry } = parsedPhone
      if (nationalNumber !== phone) {
        setPhone(nationalNumber)
      }

      if (parsedCountry && parsedCountry !== country) {
        const match = countries.find(({ cca2 }) => parsedCountry === cca2)
        setCountry(match.cca2)
      }
    }
  }, [value])

  React.useEffect(() => {
    const selectedCountry = countries.find((option) => option.cca2 === country)
    const formatted = `${selectedCountry.countryCode}${phone}`
    onChange({
      target: {
        value: formatted,
        name: id || name
      }
    })
  }, [phone, country])

  const getRenderValue = (cca2) => {
    if (!cca2) {
      return
    }

    const selectedCountry = countries.find((option) => option.cca2 === cca2)

    if (!selectedCountry) {
      return
    }

    return `${selectedCountry.flag} ${selectedCountry.countryCode}`
  }

  const handleChangePhone = (e) => {
    setPhone(e.target.value)
  }

  const handleChangeCountry = (e) => {
    setCountry(e.target.value)
  }

  const handleBlur = () => {
    const selectedCountry = countries.find((option) => option.cca2 === country)
    const formatted = `${selectedCountry.countryCode}${phone}`

    onBlur({
      target: {
        value: formatted,
        name: id || name
      }
    })
  }

  return (
    <div style={{ marginTop: 8, marginBottom: 8, ...containerStyle }}>
      <InputLabel id='country-label' error={error}>{label}</InputLabel>
      <div style={{ marginTop: 4, display: 'flex', flexDirection: 'row' }}>
        <CountrySelect
          MenuProps={{ style: { height: 400 } }}
          labelId='country-label'
          name='countryCode'
          value={country}
          onChange={handleChangeCountry}
          renderValue={getRenderValue}
          error={error}
        >
          {filteredCountries.map((country) => (
            <MenuItem key={'country_' + country.cca2} value={country.cca2}>
              {country.flag} {country.name} {country.countryCode}
            </MenuItem>
          ))}
        </CountrySelect>
        <NumberInput
          fullWidth
          style={{ justifyContent: 'flex-end', paddingBottom: 0, marginBottom: 0 }}
          name='phone'
          type='phone'
          value={phone}
          onChange={handleChangePhone}
          onBlur={handleBlur}
          error={error}
          InputProps={{
            inputComponent: isBR ? InputMaskedPhone : NumberString,
            ...InputProps
          }}
          inputProps={inputProps}
        />
      </div>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  )
}

export default PhoneInput
