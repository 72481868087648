import React from 'react'

const Linkedin = ({ fill = '#293337', color = '#FFFF', ...rest }) => (
  <svg {...rest} viewBox='0 0 24 25' fill="none" width='24' height='25' xmlns="http://www.w3.org/2000/svg">
    <rect y="0.600098" width="24" height="24" rx="12" fill={fill} />
    <g clipPath="url(#clip0_1186_1948)">
    <path d="M14.7682 18.6001H9.25942C8.83304 18.6025 8.41037 18.5208 8.01562 18.3596C7.62088 18.1983 7.26184 17.9608 6.95905 17.6606C6.65627 17.3604 6.41571 17.0033 6.25114 16.61C6.08658 16.2166 6.00125 15.7947 6.00005 15.3683V9.85947C5.99763 9.43308 6.07938 9.01041 6.2406 8.61567C6.40182 8.22093 6.63934 7.86188 6.93957 7.5591C7.23979 7.25632 7.59681 7.01575 7.99017 6.85119C8.38352 6.68663 8.80549 6.6013 9.23188 6.6001H14.7407C15.5971 6.60252 16.4177 6.94379 17.0233 7.54935C17.6288 8.15492 17.9701 8.97554 17.9725 9.83193V15.3407C17.9761 15.7651 17.8961 16.1861 17.7371 16.5796C17.578 16.9731 17.343 17.3314 17.0454 17.6341C16.7479 17.9367 16.3936 18.1778 16.0029 18.3436C15.6122 18.5093 15.1926 18.5965 14.7682 18.6001ZM9.23188 7.51823C8.9277 7.51702 8.62629 7.57603 8.34503 7.69188C8.06377 7.80772 7.80823 7.9781 7.59314 8.19319C7.37805 8.40828 7.20768 8.66382 7.09183 8.94508C6.97599 9.22634 6.91697 9.52775 6.91819 9.83193V15.3407C6.91819 15.9519 7.16098 16.5381 7.59316 16.9703C8.02534 17.4024 8.6115 17.6452 9.2227 17.6452H14.7315C15.0337 17.6464 15.3332 17.588 15.6127 17.4732C15.8922 17.3583 16.1464 17.1895 16.3605 16.9762C16.5746 16.7629 16.7445 16.5095 16.8604 16.2304C16.9763 15.9513 17.036 15.6521 17.036 15.3499V9.84111C17.0409 9.23305 16.8053 8.64769 16.3805 8.21259C15.9558 7.77748 15.3762 7.5279 14.7682 7.51823H9.23188Z" fill={color}/>
    <path d="M9.60848 15.8271C9.48673 15.8271 9.36996 15.7788 9.28387 15.6927C9.19778 15.6066 9.14941 15.4898 9.14941 15.3681V12.0444C9.17073 11.9384 9.2281 11.843 9.31179 11.7744C9.39548 11.7059 9.50031 11.6685 9.60848 11.6685C9.71665 11.6685 9.82149 11.7059 9.90517 11.7744C9.98886 11.843 10.0462 11.9384 10.0675 12.0444V15.3681C10.0675 15.4898 10.0192 15.6066 9.93309 15.6927C9.847 15.7788 9.73023 15.8271 9.60848 15.8271Z" fill={color}/>
    <path d="M14.5938 15.8273C14.4721 15.8273 14.3553 15.779 14.2692 15.6929C14.1831 15.6068 14.1348 15.49 14.1348 15.3683V13.431C14.1348 13.1875 14.038 12.954 13.8659 12.7818C13.6937 12.6096 13.4601 12.5129 13.2166 12.5129C12.9731 12.5129 12.7396 12.6096 12.5674 12.7818C12.3952 12.954 12.2985 13.1875 12.2985 13.431V15.3683C12.2772 15.4743 12.2198 15.5697 12.1361 15.6382C12.0524 15.7068 11.9476 15.7442 11.8394 15.7442C11.7313 15.7442 11.6264 15.7068 11.5427 15.6382C11.4591 15.5697 11.4017 15.4743 11.3804 15.3683V13.431C11.3804 12.944 11.5738 12.4769 11.9182 12.1326C12.2626 11.7882 12.7296 11.5947 13.2166 11.5947C13.7036 11.5947 14.1707 11.7882 14.5151 12.1326C14.8594 12.4769 15.0529 12.944 15.0529 13.431V15.3683C15.0529 15.49 15.0045 15.6068 14.9184 15.6929C14.8324 15.779 14.7156 15.8273 14.5938 15.8273Z" fill={color}/>
    <path d="M9.60809 10.7777C9.52894 10.7777 9.45058 10.7619 9.37757 10.7314C9.30456 10.7008 9.23835 10.656 9.18282 10.5996C9.12728 10.5432 9.08351 10.4764 9.05407 10.4029C9.02462 10.3294 9.01008 10.2508 9.0113 10.1717C9.01254 10.0163 9.07526 9.86777 9.18575 9.75853C9.23982 9.70063 9.3052 9.65446 9.37785 9.62287C9.4505 9.59128 9.52887 9.57496 9.60809 9.5749C9.6933 9.56435 9.77978 9.57203 9.8618 9.59743C9.94382 9.62284 10.0195 9.66538 10.0838 9.72224C10.1482 9.7791 10.1997 9.84898 10.235 9.92726C10.2703 10.0055 10.2885 10.0904 10.2885 10.1763C10.2885 10.2621 10.2703 10.347 10.235 10.4253C10.1997 10.5036 10.1482 10.5735 10.0838 10.6303C10.0195 10.6872 9.94382 10.7297 9.8618 10.7551C9.77978 10.7805 9.6933 10.7882 9.60809 10.7777ZM9.60809 9.85952C9.52443 9.85949 9.44405 9.89208 9.38405 9.95038C9.32404 10.0087 9.28913 10.0881 9.28674 10.1717C9.28985 10.2341 9.31057 10.2943 9.3465 10.3454C9.38242 10.3965 9.43208 10.4363 9.48973 10.4603C9.54738 10.4844 9.61066 10.4915 9.67223 10.4811C9.73379 10.4706 9.79114 10.4429 9.83762 10.4012C9.86941 10.372 9.8948 10.3366 9.91218 10.297C9.92956 10.2575 9.93856 10.2149 9.93862 10.1717C9.93743 10.1295 9.92793 10.0879 9.91068 10.0494C9.89342 10.0109 9.86874 9.9761 9.83804 9.94711C9.80734 9.91812 9.77124 9.89547 9.73178 9.88044C9.69232 9.86541 9.65029 9.8583 9.60809 9.85952Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_1186_1948">
    <rect width="12" height="12" fill={color} transform="translate(6 6.6001)"/>
    </clipPath>
    </defs>
  </svg>
)

export default Linkedin
