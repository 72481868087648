import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import CustomCheck from '../../../../../components/Icons/Check'

export const IconContainer = styled.div`
  width: 24px;
`

export const Check = styled(CustomCheck)`
  width: 16px;
  fill: ${({ theme }) => theme.palette.primary.main};
  margin-right: 8px;
  stroke: transparent;
  filter: drop-shadow(0px 3px 6px #f4812040);
`
export const Item = styled.div`
  display: flex;
  margin-bottom: 8px;
`
export const Text = styled(Typography).attrs({ variant: 'body1' })`
  margin-bottom: 24px;
`

export const TextItem = styled(Typography).attrs({ variant: 'body1' })``
