import React from 'react'
import styled from 'styled-components'

import dictionary from '../../../../../core/dictionary'

import ExpansionPanel from '../../../../../components/ExpansionPanel'
import CustomTooltip from '../../../../../components/Tooltip'

import {
  TooltipContent,
  IconContainer,
  Text,
  BulletText,
  Item,
  Check,
  TextPrimary,
  ContentWrapper,
  BulletContainer,
  Wrapper,
  TextWithBoldPrimary
} from '../../../styles'

const ByBioApplyingText = styled(Text)`
  > b:last-child {
    color: ${({ theme }) => theme.colors.primary30}
  }
`

const Tooltip = ({ children }) => (
  <CustomTooltip title={dictionary.result.financialPlan.TOOLTIP}>
    <TooltipContent>
      {children}
    </TooltipContent>
  </CustomTooltip>
)

const RetirementPlanCard = ({
  assetsToGenerateIncome,
  age,
  bioColor,
  retirementAge,
  yearsUntilRetirement,
  yearsAfterRetirement,
  lifeExpectancy,
  monthlyRealRate,
  monthlyIncomeUntilDeath,
  expenses,
  maxAgeToConsumeAssets,
  requiredPaymentAmount,
  savingsCapacity,
  yearlyNriRealRate,
  futureValue,
  retirementPayment,
  showRequiredPayment,
  showMaxAgeToConsumeAssets,
  hasNoSavings
}) => {
  return (
    <ExpansionPanel title={dictionary.result.financialPlan.RETIREMENT_PLAN}>
      <BulletContainer>
        <BulletText>{dictionary.result.financialPlan.INCOME_BY_CURRENT_ASSETS}</BulletText>
      </BulletContainer>
      <Wrapper>
        <Text>{dictionary.result.financialPlan.ASSETS_TO_GENERATE_INCOME(assetsToGenerateIncome)}</Text>
        <Text>{dictionary.result.financialPlan.YEARS_TO_ACCUMULATE(age, retirementAge, yearsUntilRetirement, yearsAfterRetirement, lifeExpectancy)}</Text>
        <Text>{dictionary.result.financialPlan.BY_APPLYING(assetsToGenerateIncome, monthlyRealRate, Tooltip)}</Text>
      </Wrapper>
      <ContentWrapper>
        <Item>
          <IconContainer>
            <Check />
          </IconContainer>
          <div>
            <Text>{dictionary.result.financialPlan.MONTHLY_INCOME(lifeExpectancy)}</Text>
            <TextPrimary>{monthlyIncomeUntilDeath}</TextPrimary>
          </div>
        </Item>
        <Item>
          <IconContainer>
            <Check />
          </IconContainer>
          {showMaxAgeToConsumeAssets
            ? (
                <div>
                  <Text>{dictionary.result.financialPlan.CAN_EXPEND(expenses)}</Text>
                  <TextPrimary>{`${maxAgeToConsumeAssets} ${dictionary.result.financialPlan.YEARS}`}</TextPrimary>
                </div>
              )
            : <Text>{dictionary.result.financialPlan.CAN_EXPEND_ALL(expenses)}</Text>
          }
        </Item>
      </ContentWrapper>
      <BulletContainer>
        <BulletText>{dictionary.result.financialPlan.REAL_RETIREMENT_PLAN}</BulletText>
      </BulletContainer>
      <Wrapper>
        <TextWithBoldPrimary>{dictionary.result.financialPlan.BIO_RATE(bioColor, yearlyNriRealRate)}</TextWithBoldPrimary>
        <ByBioApplyingText>{dictionary.result.financialPlan.BY_BIO_APPLYING(bioColor, assetsToGenerateIncome)}</ByBioApplyingText>
      </Wrapper>
      <ContentWrapper>
        <Item>
          <IconContainer>
            <Check />
          </IconContainer>
          {showRequiredPayment
            ? (
                <div>
                  <Text>{dictionary.result.financialPlan.RETIREMENT_INCOME_DESIRED(expenses, retirementAge, lifeExpectancy)}</Text>
                  <TextPrimary>{requiredPaymentAmount}</TextPrimary>
                </div>
              )
            : <Text>{dictionary.result.financialPlan.ALREADY_HAVE_RETIREMENT_INCOME(expenses, retirementAge, lifeExpectancy)}</Text>
          }
        </Item>
        <Item>
          <IconContainer>
            <Check />
          </IconContainer>
          {hasNoSavings
            ? <Text>{dictionary.result.financialPlan.NO_SAVINGS}</Text>
            : (
                <div>
                  <Text>{dictionary.result.financialPlan.SAVINGS_INVESTMENT(savingsCapacity, age, retirementAge, lifeExpectancy)}</Text>
                  <TextPrimary>{retirementPayment}</TextPrimary>
                </div>
              )
          }
        </Item>
      </ContentWrapper>
    </ExpansionPanel>
  )
}

export default RetirementPlanCard
