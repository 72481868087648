import React from 'react'
import dictionary from '../../../../../core/dictionary'

import ExpansionPanel from '../../../../../components/ExpansionPanel'

import { Text } from '../../../styles'

const SecurityReserveCard = ({ recommendedSecurityReserve, months }) => {
  return (
    <ExpansionPanel title={dictionary.result.financialPlan.EMERGENCY_RESERVE}>
      <Text>{dictionary.result.financialPlan.RECOMMENDED_SECURITY_RESERVE(recommendedSecurityReserve)}</Text>
      <Text>{dictionary.result.financialPlan.MONTHS_SECURITY_RESERVE(months)}</Text>
      <Text>{dictionary.result.financialPlan.INVESTED_IN}</Text>
    </ExpansionPanel>
  )
}

export default SecurityReserveCard
