import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from 'styled-components'

import dictionary from '../../../core/dictionary'

import ArrowBack from '../../Icons/ArrowBack'

import { Container, Title, InvisibleDiv } from './styles'

const Header = ({ step, onBack, params }) => {
  const theme = useTheme()

  return (
    <Container>
      {step > 1 && !params.resultId
        ? (
          <IconButton onClick={onBack}>
            <ArrowBack fill={theme.colors.neutral5}/>
          </IconButton>
          )
        : <InvisibleDiv />
      }
      <Title>
        {dictionary.bio.BIO}
      </Title>
      <InvisibleDiv />
    </Container>
  )
}

export default Header
