import React, { createRef, useCallback, useState } from 'react'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import dictionary from '../../../core/dictionary'
import InputMaskedCurrency from '../../../components/Form/InputMaskedCurrency'
import ButtonsContainer from '../../../components/ButtonsContainer'

import validationSchema from './validation.schema'
import useResizeListener from '../../../core/hooks/useResizeListener'

const renderSpouseFields = ({ dict, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <TextField
      id='spouseIncome'
      name='spouseIncome'
      type='tel'
      value={values.spouseIncome}
      label={dict.client.financial.spouseIncome.bioLabel}
      onBlur={handleBlur}
      onChange={handleChange}
      error={Boolean(touched.spouseIncome && errors.spouseIncome)}
      helperText={touched.spouseIncome ? errors.spouseIncome : ''}
      margin='normal'
      fullWidth
      InputProps={{
        inputComponent: InputMaskedCurrency
      }}
    />
  )
}

const BioStep5 = ({ formValues, onCancel, onContinue, dict }) => {
  const [expectationLabelSize, setExpectationLabelSize] = useState(16)
  const expectationRef = createRef()

  const onSubmit = (values) => {
    onContinue(values)
  }

  const hasPartner = formValues.civilStatus === 'STABLE_UNION' || formValues.civilStatus === 'MARRIED'

  if (!hasPartner) {
    formValues.spouseIncome = 0
  }

  const handleResize = useCallback(() => {
    if (expectationRef.current) {
      setExpectationLabelSize(expectationRef.current.firstChild.clientHeight)
    }
  }, [expectationRef])

  useResizeListener(handleResize)

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="finances"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='personalIncome'
            name='personalIncome'
            type='tel'
            value={values.personalIncome}
            label={dict.client.financial.personalIncome.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.personalIncome && errors.personalIncome)}
            helperText={touched.personalIncome ? errors.personalIncome : ''}
            margin='normal'
            fullWidth
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          {hasPartner && renderSpouseFields({ dict, values, errors, touched, handleChange, handleBlur })}
          <TextField
            id='otherIncome'
            name='otherIncome'
            type='tel'
            value={values.otherIncome}
            label={dict.client.financial.otherIncome.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.otherIncome && errors.otherIncome)}
            helperText={(touched.otherIncome && errors.otherIncome) ? errors.otherIncome : dict.client.financial.otherIncome.helperText}
            margin='normal'
            fullWidth
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='expectation'
            name='expectation'
            select
            value={values.expectation}
            label={dict.client.financial.expectation.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.expectation && errors.expectation)}
            helperText={touched.expectation ? errors.expectation : ''}
            fullWidth
            margin='normal'
            ref={expectationRef}
            InputLabelProps={{
              style: { top: expectationLabelSize > 16 ? -16 : 0 }
            }}
          >
            {dict.client.financial.expectation.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='expenses'
            name='expenses'
            type='tel'
            value={values.expenses}
            label={hasPartner ? dict.client.financial.expenses.bioLabelPlural : dict.client.financial.expenses.bioLabelSingular}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.expenses && errors.expenses)}
            helperText={touched.expenses ? errors.expenses : ''}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep5
