import React from 'react'
import { BioStagePin, ThermometerWrapper, ThermometerStage } from './styles'
import { styles } from '../../core/theme/styles'

const bioColors = [
  styles.colors.tertiary30,
  styles.colors.positive30,
  styles.colors.warning30,
  styles.colors.primary30,
  styles.colors.negative40
]

const BioThermometer = ({ stage }) => (
  <ThermometerWrapper>
    <BioStagePin size={32} color={bioColors[stage]} stage={stage} />
    {bioColors.map(color => <ThermometerStage key={color} color={color} />)}
  </ThermometerWrapper>
)

export default BioThermometer
