import React from 'react'
import { Formik, Form } from 'formik'
import dictionary from '../../../core/dictionary'
import validationSchema from './validation.schema'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Slider from '../../../components/Form/Slider'
import ButtonsContainer from '../../../components/ButtonsContainer'

const riskToleranceLabels = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  }
]

const BioStep2 = ({ formValues, onCancel, onContinue, dict }) => {
  const onSubmit = (values) => {
    onContinue(values)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="behavior"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='behaviorDescription'
            name='behaviorDescription'
            select
            value={values.behaviorDescription}
            label={dict.client.financial.behaviorDescription.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.behaviorDescription && errors.behaviorDescription)}
            helperText={touched.behaviorDescription ? errors.behaviorDescription : ''}
            fullWidth
            margin='normal'
          >
            {dict.client.financial.behaviorDescription.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='financialSituation'
            name='financialSituation'
            select
            value={values.financialSituation}
            label={dict.client.financial.financialSituation.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.financialSituation && errors.financialSituation)}
            helperText={touched.financialSituation ? errors.financialSituation : ''}
            fullWidth
            margin='normal'
          >
            {dict.client.financial.financialSituation.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='riskOpinion'
            name='riskOpinion'
            select
            value={values.riskOpinion}
            label={dict.client.financial.riskOpinion.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.riskOpinion && errors.riskOpinion)}
            helperText={touched.riskOpinion ? errors.riskOpinion : ''}
            fullWidth
            margin='normal'
            style={{ paddingBottom: 16 }}
          >
            {dict.client.financial.riskOpinion.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <Slider
            id='riskTolerance'
            max={5}
            label={dict.client.financial.riskTolerance.bioLabel}
            leftLabel={dict.client.financial.riskTolerance.leftLabel}
            rightLabel={dict.client.financial.riskTolerance.rightLabel}
            marks={riskToleranceLabels}
            value={values.riskTolerance}
            onChange={handleChange}
            error={Boolean(touched.riskTolerance && errors.riskTolerance)}
            helperText={touched.riskTolerance ? errors.riskTolerance : ''}
            margin='normal'
          />
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep2
