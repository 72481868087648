import React from 'react'
import dictionary from '../../../../../core/dictionary'

import {
  BulletText,
  Check,
  Text,
  TextMutedGray,
  Item,
  BulletContainer,
  IconContainer,
  TextContainer
} from '../../../styles'

const LifeMoment = ({ age, civilStatus, occupation, timeOnCurrentWorkingBusiness, expectation, justifications, text }) => {
  return (
    <>
      <BulletContainer>
        <BulletText>{dictionary.result.knowingBio.LIFE_MOMENT}</BulletText>
      </BulletContainer>
      <Item>
        <IconContainer>
          <Check />
        </IconContainer>
        <Text>
          {dictionary.result.knowingBio.PROFILE({ age, civilStatus })}
        </Text>
      </Item>
      <Item>
        <IconContainer>
          <Check />
        </IconContainer>
        <Text>
          {dictionary.result.knowingBio.WORK_EXPERIENCE({ timeOnCurrentWorkingBusiness, occupation })}
        </Text>
      </Item>
      <Item>
        <IconContainer>
          <Check />
        </IconContainer>
        <Text>
          {dictionary.result.knowingBio.PERSPECTIVE(expectation)}
        </Text>
      </Item>
      {justifications.map(justification => (
        <Item key={justification}>
          <IconContainer>
            <Check />
          </IconContainer>
          <Text>
            {justification}
          </Text>
        </Item>
      ))}
      <TextContainer>
        <Text>{dictionary.result.knowingBio.JUSTIFICATION}</Text>
        <TextMutedGray>{text}</TextMutedGray>
      </TextContainer>
    </>
  )
}

export default LifeMoment
