import React from 'react'
import NumberFormat from 'react-number-format'

const NumberString = ({ inputRef, onBlur, onChange, value, name, ...props }) => (
  <NumberFormat
    {...props}
    value={value}
    getInputRef={inputRef}
    onBlur={() => {
      if (onBlur) {
        onBlur({ target: { value, name } })
      }
    }}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
          name
        }
      })
    }}
    decimalScale={0}
    allowNegative={false}
    allowLeadingZeros
  />
)

export default NumberString
