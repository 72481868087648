import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import smoothscroll from 'smoothscroll-polyfill'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from 'styled-components'
import ParMaisData from 'par-mais-data'

import './core/yup'
import theme from './core/theme'
import { GlobalStyle } from './core/theme/globalStyles'
import App from './containers/App'

smoothscroll.polyfill()

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'development-key',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ParMaisData.init('BIO_MB_V2.1')

ReactDOM.render(
  <ErrorBoundary>
    <StylesProvider injectFirst>
      <CssBaseline />
      <GlobalStyle />
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Route component={App} />
          </BrowserRouter>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ErrorBoundary>
  , document.getElementById('root')
)
