import React from 'react'
import dictionary from '../../../../../core/dictionary'

import ExpansionPanel from '../../../../../components/ExpansionPanel'
import { Check, IconContainer, Item, Text, TextItem } from './styles'

const HowToInvestCard = () => {
  const options = [
    dictionary.result.investments.OPEN_YOUR_ACCOUNT,
    dictionary.result.investments.ADD_BALANCE,
    dictionary.result.investments.TRADE_ASSETS
  ]
  return (
    <ExpansionPanel title={dictionary.result.investments.HOW_TO_INVEST} fixed>
      <Text>{dictionary.result.investments.INVEST_MB}</Text>
      {options.map((option) => (
      <Item key={option}>
        <IconContainer>
          <Check />
        </IconContainer>
        <TextItem>{option}</TextItem>
      </Item>
      ))}
    </ExpansionPanel>
  )
}

export default HowToInvestCard
