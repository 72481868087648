import * as yup from 'yup'
import { isValid, isBefore, parse } from 'date-fns'
import dictionary from '../../../core/dictionary'

const validationSchema = yup.object().shape({
  name: yup.string().matches(/^\s*[\S]+(\s[\S]+)+\s*$/, dictionary.validation.FULL_NAME_REQUIRED).required(dictionary.validation.REQUIRED_FIELD),
  email: yup.string().email(dictionary.validation.INVALID_EMAIL)
    .required(dictionary.validation.REQUIRED_FIELD),
  phone: yup.string().required(dictionary.validation.REQUIRED_FIELD)
    .test('checkPhone', dictionary.validation.INVALID_PHONE, (value) => {
      return value && value.match(/\d/g).length >= 10
    }),
  dateOfBirth: yup.string().required(dictionary.validation.REQUIRED_FIELD)
    .test('checkValidDate', dictionary.validation.INVALID_DATE, (value) => {
      const today = new Date()
      const date = parse(value, 'dd/MM/yyyy', today)
      return isValid(date) && isBefore(date, today)
    }),
  gender: yup.string().required(dictionary.validation.REQUIRED_FIELD)
})

export default validationSchema
