import React from 'react'

import dictionary from '../../../core/dictionary'

import homeImage from '../../../components/Images/home.png'
import homeImage2x from '../../../components/Images/home_2x.png'

import {
  Container,
  Main,
  TextContainer,
  ImageContainer,
  Image,
  AgreementText,
  ButtonContainer,
  StartButton
} from './styles'
import { Text } from '../styles'

const Home = ({ onStart }) => {
  return (
    <Container>
      <Main>
        <TextContainer>
          <Text>
            {dictionary.bio.ONBOARDING_1}
          </Text>
          <Text>
            {dictionary.bio.ONBOARDING_2}
          </Text>
          <Text>
            {dictionary.bio.ONBOARDING_3}
          </Text>
        </TextContainer>
        <ImageContainer>
          <Image srcSet={`${homeImage}, ${homeImage2x} 2x`} alt={dictionary.alt.IMAGE_WOMAN} />
        </ImageContainer>
      </Main>
      <AgreementText>
        {dictionary.bio.TERMS_AGREEMENT('https://www.mercadobitcoin.com.br/privacidade')}
      </AgreementText>
      <ButtonContainer>
        <StartButton type='button' onClick={onStart}>
          {dictionary.button.START}
        </StartButton>
      </ButtonContainer>
    </Container>
  )
}

export default Home
