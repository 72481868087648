import * as yup from 'yup'
import { computeAge, parseDate } from '../../../core/utils'
import dictionary from '../../../core/dictionary'

const validationSchema = (birthDate, cnpjRequiredLIst) => yup.object().shape({
  mainOccupation: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  timeOnCurrentWorkingBusiness: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  ageToStopWorking: yup.number().required(dictionary.validation.REQUIRED_FIELD)
    .test('checkAgeToLiveFromIncome', dictionary.validation.INVALID_AGE, (value) => {
      if (!value) return
      const age = computeAge(parseDate(birthDate))
      return value >= age && value < 110
    }),
  civilStatus: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  dependents: yup.number().required(dictionary.validation.REQUIRED_FIELD)
})

export default validationSchema
