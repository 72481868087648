import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Container = styled.header`
  position: fixed;
  box-sizing: content-box;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral50};
  border-radius: 0px 0px 20px 20px;
  z-index: 999;

  @media screen and (max-width: 920px) {
    position: absolute;
  }
`

export const Title = styled(Typography).attrs({
  variant: 'h1'
})`
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral5};
`

export const InvisibleDiv = styled.div`
  width: 48px;
  height: 48px;
`
