import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'

export const Panel = styled(Accordion)`
  padding: 8px;
  box-shadow: 0px 3px 12px #3E455740;

  &.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordion-rounded {
    border-radius: 10px;
  }

  &::before {
    background-color: unset;
  }
`

export const PanelHeader = styled(AccordionSummary)`
  padding: 0px 12px;

  & > .MuiAccordionSummary-content {
    justify-content: space-between;
    align-items: center;

    &.Mui-expanded {
      margin: 16px 0;
    }
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
  }
`

export const PanelTitle = styled(Typography).attrs({
  variant: 'h6'
})``
