import React from 'react'
import { Formik, Form } from 'formik'
import dictionary from '../../../core/dictionary'
import validationSchema from './validation.schema'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InputNumber from '../../../components/Form/InputNumber'
import ButtonsContainer from '../../../components/ButtonsContainer'

const BioStep3 = ({ formValues, onCancel, onContinue, dict }) => {
  const onSubmit = (values) => {
    onContinue(values)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(formValues.dateOfBirth)}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="life_moment"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='mainOccupation'
            name='mainOccupation'
            select
            value={values.mainOccupation}
            label={dict.client.professional.mainOccupation.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.mainOccupation && errors.mainOccupation)}
            helperText={touched.mainOccupation ? errors.mainOccupation : ''}
            fullWidth
            margin='normal'
          >
            {dict.client.professional.mainOccupation.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='timeOnCurrentWorkingBusiness'
            name='timeOnCurrentWorkingBusiness'
            select
            value={values.timeOnCurrentWorkingBusiness}
            label={dict.client.professional.timeOnCurrentWorkingBusiness.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.timeOnCurrentWorkingBusiness && errors.timeOnCurrentWorkingBusiness)}
            helperText={touched.timeOnCurrentWorkingBusiness ? errors.timeOnCurrentWorkingBusiness : ''}
            fullWidth
            margin='normal'
          >
            {dict.client.professional.timeOnCurrentWorkingBusiness.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='ageToStopWorking'
            name='ageToStopWorking'
            type='tel'
            value={values.ageToStopWorking}
            label={dict.client.professional.ageToStopWorking.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.ageToStopWorking && errors.ageToStopWorking)}
            helperText={(touched.ageToStopWorking && errors.ageToStopWorking) ? errors.ageToStopWorking : dict.client.professional.ageToStopWorking.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputNumber
            }}
          />
          <TextField
            id='civilStatus'
            name='civilStatus'
            select
            value={values.civilStatus}
            label={dict.client.civilStatus.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.civilStatus && errors.civilStatus)}
            helperText={touched.civilStatus ? errors.civilStatus : ''}
            fullWidth
            margin='normal'
          >
            {dict.client.civilStatus.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </TextField>
          <TextField
            id='dependents'
            name='dependents'
            type='tel'
            value={values.dependents}
            label={dict.client.dependents.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.dependents && errors.dependents)}
            helperText={touched.dependents ? errors.dependents : dict.client.dependents.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputNumber
            }}
          />
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep3
