// VALIDATIONS
export const REQUIRED_FIELD = 'Campo obrigatório'
export const INVALID_EMAIL = 'E-mail inválido'
export const INVALID_PHONE = 'Número de telefone inválido'
export const INVALID_DATE = 'Data inválida'
export const INVALID_AGE = 'Idade inválida'
export const INVALID_CPF = 'Insira um CPF válido'
export const INVALID_CNPJ = 'CNPJ inválido'
export const POSITIVE_NUMBER_REQUIRED = 'O número precisa ser maior que 0 (zero)'
export const FULL_NAME_REQUIRED = 'Insira seu nome completo'
