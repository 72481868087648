import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className, arrow: className }} />
))`
  &.MuiTooltip-tooltip {
    font-size: 14px;
    white-space: pre-line;
    padding: 16px;
    background-color: white;
    box-shadow: 0 3px 12px #3E455725;
    color: black;
  }
`

export default CustomTooltip
