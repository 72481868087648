import * as yup from 'yup'

import dictionary from '../dictionary'
import { isCNPJValid } from '../utils'

yup.addMethod(yup.string, 'cnpj', function () {
  return this.test('validate-cnpj', dictionary.validation.INVALID_CNPJ, (value) => {
    if (value) {
      return isCNPJValid(value)
    }

    return true
  })
})
