import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const AgreementText = styled(Typography).attrs({
  variant: 'body1'
})`
  text-align: center;

  & > a {
    color: ${({ theme }) => theme.colors.neutral50};
  }

  @media screen and (max-width: 920px) {
    text-align: left;
  }
`
