import React from 'react'
import { useTheme } from 'styled-components'

import dictionary from '../../../../core/dictionary'
import { goToMercadoBitcoinRegister, goToDashboard, optionsToReduce } from '../../../../core/utils'
import useQuery from '../../../../core/hooks/useQuery'

import TotalInvestmentsCard from './TotalInvestmentsCard'
import StrategiesCards from './StrategiesCards'
import NextStepsCard from './NextStepsCard'
import HowToInvestCard from './HowToInvestCard'

import { Title, Button, TitleContainer, Wrapper } from '../../styles'

const Investments = ({ data: { payload, result }, dict }) => {
  const theme = useTheme()
  const query = useQuery()
  const { cryptoStrategy, portfolioStrategy: traditionalPortfolio, riskProfile, securityReserve } = result
  const { recommendation: cryptoRecommendation, portfolio: cryptoPortfolio } = cryptoStrategy
  const {
    investments,
    investmentsInCrypto,
    personalIncome,
    otherIncome,
    spouseIncome,
    expenses,
    personalProperties,
    investmentProperties,
    liabilities,
    assets,
    otherAssets
  } = payload

  const traditionalStrategyColors = {
    MULTIMARKET: theme.colors.chart1,
    VARIABLE_INCOME: theme.colors.chart2,
    POST_FIXED_INCOME: theme.colors.chart3,
    PRE_FIXED_INCOME: theme.colors.chart4,
    INFLATION_INCOME: theme.colors.chart5,
    INTERNATIONAL: theme.colors.chart6,
    REAL_ESTATE: theme.colors.chart7
  }

  const cryptoStrategyColors = {
    BTC: theme.colors.chart5,
    ETH: theme.colors.chart4,
    OTHERS: theme.colors.chart6
  }

  const traditionlStrategyMap = optionsToReduce(dict.product.strategy.options)

  const parsedTraditionalPortfolio = Object.keys(traditionalPortfolio).map((key) => ({
    label: traditionlStrategyMap[key].label,
    value: traditionalPortfolio[key],
    color: traditionalStrategyColors[key]
  }))

  const cryptoStrategyMap = optionsToReduce(dict.product.cryptoStrategy.options)

  const parsedCryptoPortfolio = Object.keys(cryptoPortfolio).map((key) => ({
    label: cryptoStrategyMap[key].label,
    value: cryptoPortfolio[key] / cryptoRecommendation,
    color: cryptoStrategyColors[key]
  }))

  const totalInvestments = investments + investmentsInCrypto
  const totalSavings = personalIncome + spouseIncome + otherIncome - expenses
  const totalAssets = totalInvestments + assets + otherAssets + personalProperties + investmentProperties - liabilities
  const investmentsInCryptoPercentage = Number(((cryptoRecommendation / totalInvestments) * 100).toFixed(1))
  const investmentsTraditionalPercentage = Number((((totalInvestments - cryptoRecommendation) / totalInvestments) * 100).toFixed(1))
  const bioNumber = riskProfile.bioResult.bioColor
  const bioColor = riskProfile.bioFeedBacks.profile.bioColor
  const showReserveSection = securityReserve.reserve > totalInvestments
  const showCryptoSection = bioNumber >= 1 && !showReserveSection
  const section = showReserveSection ? 'RESERVE' : showCryptoSection ? 'CRYPTO' : 'NO_CRYPTO'

  const totalInvestmentsProps = {
    section,
    totalInvestments,
    traditionalRecommendation: totalInvestments - cryptoRecommendation,
    cryptoRecommendation
  }

  const strategiesCardProps = {
    showCryptoSection,
    bioColor,
    traditional: {
      total: totalInvestments - cryptoRecommendation,
      investmentsPercentage: (totalInvestments - cryptoRecommendation) / totalInvestments,
      portfolio: parsedTraditionalPortfolio
    },
    crypto: {
      total: cryptoRecommendation,
      investmentsPercentage: cryptoRecommendation / totalInvestments,
      assetsPercentage: cryptoRecommendation / totalAssets,
      portfolio: parsedCryptoPortfolio
    }
  }

  const nextStepsProps = {
    section,
    bioColor,
    savings: {
      total: totalSavings,
      crypto: (investmentsInCryptoPercentage * totalSavings) / 100,
      traditional: (investmentsTraditionalPercentage * totalSavings) / 100
    }
  }

  return (
    <>
      <TitleContainer>
        <Title>{dictionary.result.investments.INVESTMENTS}</Title>
      </TitleContainer>
      <Wrapper>
        <TotalInvestmentsCard {...totalInvestmentsProps} />
        <StrategiesCards {...strategiesCardProps} />
        <NextStepsCard {...nextStepsProps} />
        <HowToInvestCard />
      </Wrapper>
      {query.get('c')
        ? <Button onClick={goToDashboard}>{dictionary.button.INVEST}</Button>
        : <Button onClick={goToMercadoBitcoinRegister}>{dictionary.button.OPEN_ACCOUNT}</Button>
      }
    </>
  )
}

export default Investments
