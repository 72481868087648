import styled from 'styled-components'

const Content = styled.main`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 64px - 100px);
  margin-top: 64px;
  padding: 64px 32px 32px;
  display: flex;
  flex-direction: row-reverse;

  @media screen and (max-height: 750px) {
    padding: 24px 32px 8px;
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
    padding: 16px 32px;
    min-height: calc(100vh - 64px - 199px);
  }

  @media screen and (max-width: 450px) {
    padding: 16px;
  }
`

export default Content
