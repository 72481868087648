import React from 'react'
import MaskedInput from 'react-text-mask'

const InputMaskedDate = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      placeholder=''
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      size='20'
    />
  )
}

export default InputMaskedDate
