import React from 'react'

const Pin = ({ size = '24', color = '#000000', ...props }) => (
  <svg {...props} focusable='false' viewBox='0 0 24 24' fill={color} stroke='none' width={size} height={size}>
    <path className='pin-layer-1' opacity='0.4' d='M12.2,3.7C16,3.8,19.1,7,19,10.8
      c0,3.9-6.5,9.3-6.7,9.5c-0.2,0.1-6.7-5.5-6.7-9.5C5.4,7,8.4,3.8,12.2,3.7z' />
    <path className='pin-layer-2' opacity='0.4' d='M12.2,3.7C16,3.8,19.1,7,19,10.8
      c0,3.9-6.5,9.3-6.7,9.5c-0.2,0.1-6.7-5.5-6.7-9.5C5.4,7,8.4,3.8,12.2,3.7z' />
    <path d='M12.1,6.5c2.5,0.1,4.5,2.2,4.5,4.7c0,2.6-4.3,6.2-4.5,6.3c-0.1,0.1-4.5-3.7-4.5-6.3
      C7.6,8.7,9.6,6.6,12.1,6.5z' />
  </svg>
)

export default Pin
