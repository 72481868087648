import React from 'react'
// Finances and Assets
export const YOUR_FINANCES_AND_ASSETS = 'Suas finanças e patrimônio'

// Finances
export const FINANCES = 'Finanças'
export const INCOMES = 'Rendas'
export const EXPENSES = 'Despesas'
export const BALANCE_TO_INVEST = <b>Saldo para investir</b>

// Assets
export const TOTAL_ASSETS = 'Patrimônio'
export const INVESTMENTS = 'Aplicações'
export const OTHER_ASSETS = 'Outros bens'
export const ASSETS = 'Bens móveis'
export const REAL_STATE = 'Imóveis'
export const PERSONAL_USE = 'Uso pessoal'
export const ALL_ASSETS = 'Total de bens'
export const ALL_LIABILITIES = 'Total de obrigações'
export const NET_WORTH = <b>Patrimônio líquido</b>
export const NET_WORTH_MEANING = 'Patrimônio Líquido: é o valor que sobraria na sua conta hoje caso você vendesse todos os seus bens e quitasse todas as suas obrigações.'
export const TOTAL = 'Total'

// Financial Situation
export const YOUR_CURRENT_FINANCIAL_SITUATION = 'Sua situação financeira atual:'
export const OF_YOUR_EARNS = 'do que ganha'
export const OF_YOUR_EXPENSES = 'ano(s) de suas despesas'
