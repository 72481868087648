import React from 'react'
import dictionary from '../../../../core/dictionary'
import { currencyFormat, decimalToPercent, goToDashboard, goToMercadoBitcoinRegister } from '../../../../core/utils'
import useQuery from '../../../../core/hooks/useQuery'

import RetirementPlanCard from './RetirementPlanCard'
import SecurityReserveCard from './SecurityReserveCard'

import { Title, Wrapper, Button, TitleContainer, TextContainer, TextWithBoldPrimary } from '../../styles'

const FinancialPlan = ({ data: { payload, result } }) => {
  const query = useQuery()
  const { ageToStopWorking: retirementAge, age, expenses } = payload
  const { securityReserve, riskProfile, retirementPlan } = result
  const { reserve, months } = securityReserve
  const { bioFeedBacks: { profile: { bioColor } } } = riskProfile
  const {
    lifeExpectancy,
    maxAgeToConsumeAssets,
    monthlyRealRate,
    yearlyNriRealRate,
    assetsToGenerateIncome,
    monthlyIncomeUntilDeath,
    requiredPaymentAmount,
    futureValueInvestingMonthlySavings,
    savingsCapacity,
    retirementPaymentInvestingMonthlySavings
  } = retirementPlan

  const retirementPlanData = {
    age,
    lifeExpectancy,
    bioColor,
    retirementAge,
    maxAgeToConsumeAssets,
    monthlyRealRate: decimalToPercent(monthlyRealRate),
    yearlyNriRealRate: decimalToPercent(yearlyNriRealRate),
    assetsToGenerateIncome: currencyFormat(assetsToGenerateIncome),
    yearsUntilRetirement: retirementAge - age,
    yearsAfterRetirement: lifeExpectancy - retirementAge,
    expenses: currencyFormat(expenses),
    monthlyIncomeUntilDeath: currencyFormat(monthlyIncomeUntilDeath),
    requiredPaymentAmount: currencyFormat(requiredPaymentAmount),
    futureValue: currencyFormat(futureValueInvestingMonthlySavings),
    savingsCapacity: currencyFormat(savingsCapacity),
    retirementPayment: currencyFormat(retirementPaymentInvestingMonthlySavings),
    showRequiredPayment: requiredPaymentAmount > 0,
    showMaxAgeToConsumeAssets: maxAgeToConsumeAssets !== null,
    hasNoSavings: savingsCapacity <= 0
  }

  const securityReserveData = {
    recommendedSecurityReserve: currencyFormat(reserve),
    months
  }

  return (
    <>
      <TitleContainer>
        <Title>{dictionary.result.financialPlan.FINANCIAL_PLAN}</Title>
      </TitleContainer>
      <TextContainer>
        <TextWithBoldPrimary>{dictionary.result.financialPlan.BASED_ON_YOUR_ANSWERS(bioColor)}</TextWithBoldPrimary>
      </TextContainer>
      <Wrapper>
        <SecurityReserveCard {...securityReserveData} />
        <RetirementPlanCard {...retirementPlanData} />
      </Wrapper>
      {query.get('c')
        ? <Button onClick={goToDashboard}>{dictionary.button.INVEST}</Button>
        : <Button onClick={goToMercadoBitcoinRegister}>{dictionary.button.OPEN_ACCOUNT}</Button>
      }
    </>
  )
}

export default FinancialPlan
