import styled from 'styled-components'

export const Balance = styled.div`
  margin-top: 32px;
`

export const QuestionMarkContainer = styled.div`
  width: 15px;
  height: 15px;
  cursor: help;

  & > svg {
    fill: ${({ theme }) => theme.colors.neutral40};
  }
`

export const NetWorthTextAndQuestionMark = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`
