import React from 'react'
// Home
export const ONBOARDING_1 = <>A BIO Financeira é uma <b>análise geral de como está a sua vida financeira</b>, levando em conta seu momento de vida, perfil comportamental, finanças e patrimônio.</>
export const ONBOARDING_2 = <>Com o resultado da BIO, também entregaremos um <b>planejamento financeiro estratégico</b> para você dar seus primeiros passos ainda hoje.</>
export const ONBOARDING_3 = 'Para que o resultado seja o mais exato possível, é preciso que você responda as perguntas com atenção. Vamos lá?'
export const TERMS_AGREEMENT = (href) => <>Ao clicar em “Continuar”, você concorda com a nossa <a href={href} target='_blank' rel='noopener noreferrer'><b>política de privacidade.</b></a></>

// Stepper
export const BIO = 'BIO Financeira'
export const KNOWING_YOU = 'Conhecendo você'
export const BEHAVIORAL_PROFILE = 'Perfil comportamental'
export const LIFE_MOMENT = 'Momento de vida'
export const PATRIMONY = 'Patrimônio'
export const FINANCE = 'Finanças'
export const INVESTMENTS_EXPERIENCE = 'Experiência em investimentos'
export const BIO_RESULT = 'Sua BIO Financeira'

// Forms
export const NOT_DECLARE = 'Prefiro não declarar'

// Footer
export const MERCADO_BITCOIN = 'Mercado Bitcoin Serviços Digitais LTDA'
export const CNPJ = 'CNPJ: 18.213.434/0001-35'
