import React from 'react'

// TITLES
export const INVESTMENTS = 'Investimentos financeiros'
export const YOUR_INVESTMENTS = 'Seus investimentos'
export const TRADITIONAL_INVESTMENTS = 'Investimentos em geral'
export const CRYPTO_INVESTMENTS = 'Investimentos em criptomoedas'
export const NEW_CONTRIBUITIONS = 'Novos aportes nos seus objetivos'
export const HOW_TO_INVEST = 'Como investir?'

// TEXTS
export const TOTAL_INVESTMENTS_BLUE = (total) => <>Como sua BIO Financeira está <b>Azul</b>, recomendamos que aloque o seu patrimônio de <b>{total}</b> em investimentos mais conservadores, como a Renda Fixa Digital.</>
export const TOTAL_INVESTMENTS = (total) => <>Considerando o valor total de seus investimentos de <b>{total}</b>, recomendamos que você invista:</>
export const PERCENT_TRADITIONAL = 'investimentos em geral'
export const PERCENT_CRYPTO = 'criptomoedas'
export const INVESTMENTS_RECOMMENDATION = (bioColor) => <>Atualmente, recomendamos a alocação de investimentos abaixo para pessoas com BIO Financeira <b>{bioColor}</b>.</>
export const TRADER = 'Se você já é investidor profissional, a recomendação pode não se encaixar em seu perfil. Caso queira aprofundar ainda mais, entre em contato com a gente.'
export const TRADITIONAL_RECOMMENDATION = (bioColor) => <>Para a BIO Financeira <b>{bioColor}</b>, atualmente recomendamos a alocação por estratégia a seguir.</>
export const INTEREST_IN_CRYPTO = <>Também recomendamos investimentos em criptomoedas. Caso não saiba o que são, confira <a href='https://blog.mercadobitcoin.com.br/criptomoedas-o-que-sao-e-como-investir-com-seguranca' target='_blank' rel='noopener noreferrer'><u><b>neste link</b></u></a>.<br/><br/>Recomendamos que você aplique, nessa classe de investimentos:</>
export const CRYPTO_MAX = 'O valor de:'
export const THIS_VALUE_REPRESENTS = 'Esse valor representa:'
export const OF_YOUR_INVESTMENTS = 'Dos seus investimentos:'
export const OF_YOUR_TOTAL_ASSETS = 'Do seu patrimônio total:'
export const OUR_METODOLOGY = 'Nossa metodologia leva em consideração o total de seus investimentos, o valor de sua reserva de emergência e sua BIO Financeira.'
export const WHERE_TO_INVEST = <b>Onde investir?</b>
export const EARLY_CRYPTO_INVESTORS = 'Para o seu perfil, caso estiver iniciando agora seus investimentos no mercado de criptoativos, costumamos recomendar que os primeiros aportes sejam divididos da seguinte forma:'
export const CAN_SAVE = (savings) => <>Atualmente, você consegue poupar <b>{savings}</b> mensalmente. Com esse valor, recomendamos que você aplique:</>
export const SAVE_TRADITIONAL = (traditional) => <><b>{traditional}</b> por mês na sua carteira de investimentos em geral;</>
export const SAVE_CRYPTO = (crypto) => <><b>{crypto}</b> por mês na sua carteira de investimentos em criptomoedas.</>
export const NEXT_STEPS_NO_CRYPTO = (color, savings) => <>Atualmente, você consegue poupar <b>{savings}</b> mensalmente. Como sua BIO está {color}, recomendamos que aplique a maior parte desse valor  em investimentos mais tradicionais, como a Renda Fixa Digital e comece com valores pequenos em criptomoedas mais conhecidas, como Bitcoin e/ou Ethereum focando em aprender mais sobre as vantagens e desvantagens dessa classe de ativos.</>
export const NEXT_STEPS_CRYPTO = <>Caso queira iniciar sua jornada de investimentos em criptoativos, sugerimos a aplicação em tokens de Renda Fixa Digital (investimentos com menor volatilidade) disponíveis na  <a href='https://www.mercadobitcoin.com.br' target='_blank' rel='noopener noreferrer'>plataforma do Mercado Bitcoin.</a></>
export const NEXT_STEPS_RESERVE = (savings) => <>Atualmente, você consegue poupar <b>{savings}</b> mensalmente. Como você ainda não possui a quantidade sugerida de reserva de emergência, recomendamos que aplique todo esse valor em investimentos mais tradicionais de alta liquidez, como títulos do Tesouro SELIC. No futuro, após constituir sua reserva, poderá direcionar sua estratégia de investimentos para criptoativos, que oferecem um maior potencial de retorno no longo prazo.</>
export const TOTAL_INVESTMENTS_RESERVE = (total) => <>Como você ainda não possui o valor sugerido de reserva de emergência, recomendamos que foque primeiro em constituir a sua reserva, alocando o total de <b>{total}</b> em investimentos mais tradicionais.</>
export const NO_SAVINGS = 'Atualmente, você não consegue poupar mensalmente. Sugerimos cortar gastos ou aumentar seus ganhos.'
export const INVEST_MB = <>Para começar a investir em criptoativos e Renda Fixa Digital, caso ainda não possua conta no MB, abra agora e conte com a segurança da maior plataforma de ativos digitais da América Latina.<br/><br/>Comece a investir em apenas 3 passos:</>
export const OPEN_YOUR_ACCOUNT = 'Abra sua conta;'
export const ADD_BALANCE = 'Adicione saldo;'
export const TRADE_ASSETS = 'Negocie ativos.'
export const UNDERSTAND_DIFFERENCE = <>Entenda também as diferenças entre Bitcoin e Ethereum no <a href='https://blog.mercadobitcoin.com.br/bitcoin-vs-ethereum' target='_blank' rel='noopener noreferrer'><u><b>nosso blog</b></u></a>.</>
