const colors = {
  white: '#FFFFFF',
  primary5: '#FFEAE5',
  primary10: '#FFC0B2',
  primary20: '#FF8166',
  primary30: '#EF4723',
  primary40: '#CC2C0A',
  primary50: '#991B00',
  neutral5: '#F3F4F4',
  neutral10: '#CBCACC',
  neutral20: '#9DA1A3',
  neutral30: '#72777A',
  neutral40: '#474D51',
  neutral50: '#1D2327',
  tertiary30: '#0019FF',
  negative30: '#DD222E',
  negative40: '#AB111B',
  positive30: '#00875A',
  warning30: '#FFBE3D',
  chart1: '#FDD425',
  chart2: '#C36134',
  chart3: '#6EF68C',
  chart4: '#CEE2F4',
  chart5: '#FF8166',
  chart6: '#CDAE92',
  chart7: '#DAFF00'
}

const fontFamily = `'IBM Plex Sans', sans-serif`
const typographies = {
  web: {
    h1: {
      fontWeight: 700,
      fontSize: 48,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h2: {
      fontWeight: 700,
      fontSize: 34,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h4: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h5: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h6: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h7: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body1: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '160%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body3: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    label1: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    caption1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    button1: {
      fontWeight: 700,
      fontSize: 18,
      color: colors.white,
      textTransform: 'none',
      lineHeight: '100%',
      letterSpacing: 0
    },
    button2: {
      fontWeight: 700,
      fontSize: 16,
      color: colors.white,
      textTransform: 'none',
      lineHeight: '100%',
      letterSpacing: 0
    }
  },
  mobile: {
    h1: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h2: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h3: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h4: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h6: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    h7: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '130%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    body3: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    label1: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    caption1: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '140%',
      color: colors.neutral50,
      letterSpacing: 0
    },
    button1: {
      fontWeight: 700,
      fontSize: 18,
      color: colors.white,
      textTransform: 'none',
      lineHeight: '100%',
      letterSpacing: 0
    },
    button2: {
      fontWeight: 700,
      fontSize: 16,
      color: colors.white,
      textTransform: 'none',
      lineHeight: '100%',
      letterSpacing: 0
    }
  }
}

export const styles = {
  colors,
  fontFamily,
  typographies
}
