import * as yup from 'yup'
import dictionary from '../../../core/dictionary'

const validationSchema = yup.object().shape({
  behaviorDescription: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  financialSituation: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  riskOpinion: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  riskTolerance: yup.number().required(dictionary.validation.REQUIRED_FIELD)
    .min(1, dictionary.validation.REQUIRED_FIELD).max(5)
})

export default validationSchema
