import * as alt from './alt'
import * as bio from './bio'
import * as button from './button'
import * as validation from './validation'
import * as investments from './investments'
import * as financialPlan from './financialPlan'
import * as knowingBio from './knowingBio'
import * as financesAndAssets from './financesAndAssets'

export default {
  alt,
  bio,
  button,
  validation,
  result: {
    investments,
    financialPlan,
    knowingBio,
    financesAndAssets
  }
}
