import React from 'react'
import NumberFormat from 'react-number-format'

const InputMaskedCurrency = ({ id, onChange, inputRef, onBlur, value, allowNegative = false, ...other }) => (
  <NumberFormat
    {...other}
    id={id}
    prefix='R$ '
    thousandSeparator='.'
    decimalSeparator=','
    allowNegative={allowNegative}
    value={value}
    decimalScale='2'
    getInputRef={inputRef}
    onBlur={() => {
      if (onBlur) {
        onBlur({ target: { value, name: id } })
      }
    }}
    onValueChange={(values) => onChange({ target: { value: values.floatValue, name: id } })}
  />
)

export default InputMaskedCurrency
