import React from 'react'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import dictionary from '../../../core/dictionary'
import InputMaskedCurrency from '../../../components/Form/InputMaskedCurrency'
import ButtonsContainer from '../../../components/ButtonsContainer'

import validationSchema from './validation.schema'

const BioStep6 = ({ formValues, onCancel, onContinue, dict }) => {
  const onSubmit = (values) => {
    onContinue(values)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="patrimony"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='personalProperties'
            name='personalProperties'
            type='tel'
            value={values.personalProperties}
            label={dict.client.financial.personalProperties.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.personalProperties && errors.personalProperties)}
            helperText={(touched.personalProperties && errors.personalProperties) ? errors.personalProperties : dict.client.financial.personalProperties.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='investmentProperties'
            name='investmentProperties'
            type='tel'
            value={values.investmentProperties}
            label={dict.client.financial.investmentProperties.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.investmentProperties && errors.investmentProperties)}
            helperText={(touched.investmentProperties && errors.investmentProperties) ? errors.investmentProperties : dict.client.financial.investmentProperties.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='assets'
            name='assets'
            type='tel'
            value={values.assets}
            label={dict.client.financial.assets.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.assets && errors.assets)}
            helperText={(touched.assets && errors.assets) ? errors.assets : dict.client.financial.assets.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='otherAssets'
            name='otherAssets'
            type='tel'
            value={values.otherAssets}
            label={dict.client.financial.otherAssets.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.otherAssets && errors.otherAssets)}
            helperText={(touched.otherAssets && errors.otherAssets) ? errors.otherAssets : dict.client.financial.otherAssets.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <TextField
            id='liabilities'
            name='liabilities'
            type='tel'
            value={values.liabilities}
            label={dict.client.financial.liabilities.bioLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.liabilities && errors.liabilities)}
            helperText={(touched.liabilities && errors.liabilities) ? errors.liabilities : dict.client.financial.liabilities.helperText}
            fullWidth
            margin='normal'
            InputProps={{
              inputComponent: InputMaskedCurrency
            }}
          />
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep6
