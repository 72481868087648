import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Loading from '../../components/SpinnerLoading'
import {
  computeBio,
  getBio,
  getDictionary,
  sendDataToPipz,
  sendIntegrationEvent,
} from '../../core/api'
import { serializeParams, setInsiderUserObject, sendInsiderTrackingEvent } from '../../core/api/helpers'
import { scrollToTop } from '../../core/utils'
import BioStepper from './BioStepper'
import Home from './Home'
import BioResult from './Result'
import BioStep1 from './Step1'
import BioStep2 from './Step2'
import BioStep3 from './Step3'
import BioStep4 from './Step4'
import BioStep5 from './Step5'
import BioStep6 from './Step6'

const Container = styled.div`
  width: 100%;
  position: relative;
`

const componentsMap = {
  1: BioStep1,
  2: BioStep2,
  3: BioStep3,
  4: BioStep4,
  5: BioStep5,
  6: BioStep6
}

const getStepComponent = (step, props) => {
  const Component = componentsMap[step]

  if (!Component) {
    return null
  }

  return <Component {...props} />
}

const initialValues = {
  name: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  gender: '',
  behaviorDescription: '',
  financialSituation: '',
  riskOpinion: '',
  riskTolerance: 0,
  mainOccupation: '',
  timeOnCurrentWorkingBusiness: '',
  ageToStopWorking: '',
  civilStatus: '',
  dependents: '',
  investments: '',
  investmentLast12Months: '',
  investmentsInCrypto: '',
  personalIncome: '',
  otherIncome: '',
  spouseIncome: '',
  expectation: '',
  expenses: '',
  knowledgeFinancialInvestments: {
    SAVINGS: false,
    CDB: false,
    FIXED_INCOME: false,
    BACKED_TOKEN: false,
    MULTIMARKET: false,
    STOCK: false,
    CRYPTO: false,
    NONE: false
  },
  personalProperties: '',
  investmentProperties: '',
  assets: '',
  otherAssets: '',
  liabilities: ''
}

const hubspotEventMap = {
  1: `pe20129773_mktapp_____bio_by_mb____passo_1`,
  2: `pe20129773_mktapp_____bio_by_mb____passo_2`,
  3: `pe20129773_mktapp_____bio_by_mb____passo_3`,
  4: `pe20129773_mktapp_____bio_by_mb____passo_4`,
  5: `pe20129773_mktapp_____bio_by_mb____passo_5`,
  6: `pe20129773_mktapp_____bio_by_mb____passo_6`
}

const getHubspotStepEventName = (step) => {
  return hubspotEventMap[step]
}

const getInsiderStepEventName = (isLastStep) => {
  return isLastStep ? 'bio_finished' : 'bio_started'
}

const Bio = ({ history, match: { params } }) => {
  const [loading, setLoading] = useState(false)
  const [dictionary, setDictionary] = useState()
  const [step, setStep] = useState(1)
  const [values, setValues] = useState(initialValues)
  const [bioData, setBioData] = useState()
  const lastStep = Object.keys(componentsMap).length + 1
  const isLastStep = step === lastStep

  useEffect(() => {
    getDictionary()
      .then(setDictionary)
  }, [])

  useEffect(() => {
    if (params.resultId && !bioData) {
      setLoading(true)
      getBio(params.resultId)
        .then((bioResult) => {
          setBioData(bioResult)
          setStep(lastStep)
        })
        .catch(() => {
          history.replace('/')
        })
        .finally(() => setLoading(false))
    }
  }, [params])

  useEffect(() => {
    if (isLastStep && !params.resultId) {
      setLoading(true)
      computeBio(values)
        .then((bioResult) => {
          setBioData(bioResult)
          handleInsiderEvent(getInsiderStepEventName(isLastStep), bioResult)
        })
        .catch(() => {
          setStep(lastStep - 1)
        })
        .finally(() => setLoading(false))
    }
  }, [step, values, params])

  const handleStart = useCallback(() => {
    setStep((prev) => ++prev)
    scrollToTop()
  }, [setStep])

  const handleInsiderEvent = (eventName, bioResult = null) => {
    if (step === 1 || isLastStep) {
      sendInsiderTrackingEvent(eventName, bioResult)
    }
  }

  const handleUpdateForm = useCallback((values) => {
    const payload = serializeParams(values)

    sendIntegrationEvent(getHubspotStepEventName(step), { payload })
    setInsiderUserObject(payload)
    sendDataToPipz(`[MKTAPP] - [BIO by MB] - passo ${step}`, { payload })

    setValues((prev) => ({ ...prev, ...values }))
    handleInsiderEvent(getInsiderStepEventName(isLastStep))
    setStep((prev) => ++prev)
    scrollToTop()
  }, [step])

  const handleCancel = useCallback(() => {
    setStep((prev) => --prev)
    scrollToTop()
  }, [setStep])

  if (loading || !dictionary) {
    return <Loading />
  }

  return (
    <Layout step={step} onBack={handleCancel} params={params}>
      {step === 0 && (
        <Home onStart={handleStart} />
      )}
      {step >= 1 && (
        <>
          <BioStepper step={step} lastStep={lastStep} />
          <Container>
            {getStepComponent(step, {
              formValues: values,
              onContinue: handleUpdateForm,
              onCancel: handleCancel,
              dict: dictionary
            })}
            {isLastStep && bioData &&
              <BioResult data={bioData} dict={dictionary} />
            }
          </Container>
        </>
      )}
    </Layout>
  )
}

export default Bio
