import React from 'react'
import CustomTooltip from './styles'

const Tooltip = ({ title, children }) => (
  <CustomTooltip
    PopperProps={{ disablePortal: true }}
    title={title}
    enterTouchDelay={0}
    leaveTouchDelay={10000}
  >
    {children}
  </CustomTooltip>
)

export default Tooltip
