import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const PercentageBar = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral30};
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 16px;
`

export const TraditionalBar = styled.div`
  width: ${({ $value }) => $value * 100}%;
  border-radius: ${({ $value }) => $value === 1 ? '4px' : '4px 0px 0px 4px'};
  height: 8px;
  background-color: ${({ theme }) => theme.colors.primary30};
`

export const BarSubtitles = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Subtitle = styled.div``

export const TraditionalValue = styled(Typography).attrs({
  variant: 'h6',
  color: 'primary'
})``

export const TraditionalPercentage = styled(Typography).attrs({
  variant: 'subtitle1'
})``

export const CryptoValue = styled(Typography).attrs({
  variant: 'h6'
})`
  text-align: right;
  color: ${({ theme }) => theme.colors.neutral30};
`

export const CryptoPercentage = styled(Typography).attrs({
  variant: 'subtitle1'
})`
  text-align: right;
`
