import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ChartContainer = styled.div`
  position: relative;
  height: 400px;
  width: 400px;
`

export const LegendsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 50%;
`

export const Text = styled(Typography).attrs({
  variant: 'subtitle1'
})`
  color: ${({ theme }) => theme.colors.darker};
`

export const Color = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ $color }) => $color};
  margin-right: 8px;
`
export const ChartOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ $margin }) => $margin.left || 0}px;
  right: ${({ $margin }) => $margin.right || 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
`
