import axios from 'axios'
import ParMaisData from 'par-mais-data'
import { getHubspotParams, getPipzParams, serializeParams } from './helpers'

const API_URL = process.env.REACT_APP_API_URL
const ORIGIN = process.env.REACT_APP_ORIGIN.toUpperCase()

export function getDictionary () {
  return axios.get(`${API_URL}/v1/dictionary?lang=pt-BR`)
    .then(({ data }) => data.entries)
}

export function computeBio (formValues) {
  const params = serializeParams(formValues)

  return axios.post(
    `${API_URL}/v2/mkt-apps/intelligence/${ORIGIN}`,
    params
  ).then(({ data: { cuid, data: { payload, result } } }) => {
    const bioResult = { cuid, payload, result }

    sendDataToPipz('Enviou e-mail e gerou resultados - MB', bioResult)
    sendIntegrationEvent('pe20129773_mkt_app_____bio_by_mb_', bioResult)

    return bioResult
  })
}

export function getBio (cuid) {
  return axios.get(`${API_URL}/v2/mkt-apps/intelligence/${ORIGIN}/${cuid}`)
    .then(({ data: { data: { payload, result } } }) => ({ payload, result }))
}

export function sendIntegrationEvent (eventName, values) {
  const {
    user,
    payload
  } = getHubspotParams(values)

  return axios.post(`${API_URL}/v1/par-mais-data/integrate`, {
    app: 'BIO_MB_V2.1',
    eventName,
    user,
    payload
  })
}

export function sendDataToPipz (eventName, result) {
  const { user, payload } = getPipzParams(result)

  return ParMaisData.identify(
    eventName,
    payload.email,
    user,
    payload
  )
}
