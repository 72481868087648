import React from 'react'
import dictionary from '../../../../../../core/dictionary'
import { currencyFormat, decimalToPercent } from '../../../../../../core/utils'
import {
  PercentageBar,
  TraditionalBar,
  BarSubtitles,
  TraditionalValue,
  TraditionalPercentage,
  CryptoValue,
  CryptoPercentage
} from './styles'

const Bar = ({ traditionalRecommendation, cryptoRecommendation, cryptoDecimal, traditionalDecimal }) => {
  return (
    <>
      <PercentageBar>
        <TraditionalBar $value={traditionalDecimal} />
      </PercentageBar>
      <BarSubtitles>
        <div>
          <TraditionalValue>{currencyFormat(traditionalRecommendation)}</TraditionalValue>
          <TraditionalPercentage>
            {`${decimalToPercent(traditionalDecimal)} ${dictionary.result.investments.PERCENT_TRADITIONAL}`}
          </TraditionalPercentage>
        </div>
        <div>
          <CryptoValue>{currencyFormat(cryptoRecommendation)}</CryptoValue>
          <CryptoPercentage>
            {`${decimalToPercent(cryptoDecimal)} ${dictionary.result.investments.PERCENT_CRYPTO}`}
          </CryptoPercentage>
        </div>
      </BarSubtitles>
    </>
  )
}

export default Bar
