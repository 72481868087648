import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.primary30};
  padding-left: 15px;
  position: relative;
  &:before,
  &:after {
    background: ${({ theme }) => theme.colors.primary30};
    position: absolute;
    bottom: -4px;
    left: -3px;
    height: 5px;
    width: 5px;
    content: "";
    border-radius: 20px;
  }
  &:after {
    right: 0;
    top: -5px;
  }
`

const LineCircle = ({ children, ...props }) => (
  <Wrapper {...props}>
    {children}
  </Wrapper>
)

export default LineCircle
