import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const FooterWrapper = styled.footer`
  width: 100%;
  min-height: 100px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  position: absolute;
`

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    margin: 16px auto;
  }
`

export const Text = styled(Typography).attrs({
  variant: 'body2'
})`
  padding-right: 4px;
  text-align: center;
`

export const TextSubContainer = styled.div`
  display: flex;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`

export const Divider = styled(Typography).attrs({
  variant: 'body2'
})`
  padding-right: 4px;

  @media screen and (max-width: 920px) {
    display: none;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1301;

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 6px;
    font-size: 16px;
    & i {
      color: #FFFFFF;
      vertical-align: middle;
    }
  }
`

export const Polygon = styled.img`
  position: absolute;  
  bottom: 0px;
  right: 0px;
  z-Index: 1300;
  height: 65px;

  @media screen and (max-width: 920px) {
    height: 85px;
  }
`
