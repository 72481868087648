import React from 'react'
import dictionary from '../../../../../core/dictionary'
import { currencyFormat } from '../../../../../core/utils'

import ExpansionPanel from '../../../../../components/ExpansionPanel'
import WalletPieChart from '../../../../../components/WalletPieChart'
import QuestionMark from '../../../../../components/Icons/QuestionMark'
import Tooltip from '../../../../../components/Tooltip'

import { Row, TextMutedGrayBold, TextMutedGray, TextRed } from '../../../styles'
import { Balance, QuestionMarkContainer, NetWorthTextAndQuestionMark } from './styles'

const Assets = ({ chartData, totalAssets, liabilities }) => {
  const netWorth = Math.max(0, totalAssets - liabilities)
  return (
    <ExpansionPanel title={dictionary.result.financesAndAssets.TOTAL_ASSETS} fixed>
      <WalletPieChart data={chartData} totalAssets={totalAssets} />
      <Balance>
        <Row>
          <TextMutedGray>{dictionary.result.financesAndAssets.ALL_ASSETS}</TextMutedGray>
          <TextMutedGray>{currencyFormat(totalAssets)}</TextMutedGray>
        </Row>
        <Row>
          <TextMutedGray>{dictionary.result.financesAndAssets.ALL_LIABILITIES}</TextMutedGray>
          <TextRed>{currencyFormat(liabilities)}</TextRed>
        </Row>
        <Row last>
          <NetWorthTextAndQuestionMark>
            <TextMutedGray style={{ paddingRight: 8 }}>{dictionary.result.financesAndAssets.NET_WORTH}</TextMutedGray>
            <Tooltip title={dictionary.result.financesAndAssets.NET_WORTH_MEANING}>
              <QuestionMarkContainer>
                <QuestionMark />
              </QuestionMarkContainer>
            </Tooltip>
            </NetWorthTextAndQuestionMark>
          <TextMutedGrayBold>{currencyFormat(netWorth)}</TextMutedGrayBold>
        </Row>
      </Balance>
    </ExpansionPanel>
  )
}
export default Assets
