import React from 'react'
import dictionary from '../../../../../core/dictionary'

import ExpansionPanel from '../../../../../components/ExpansionPanel'
import Bar from './Bar'

import { Text } from '../../../styles'
import { currencyFormat } from '../../../../../core/utils'

const CryptoSection = ({ total }) => (
  <Text>{dictionary.result.investments.TOTAL_INVESTMENTS(total)}</Text>
)

const BlueBioSection = ({ total }) => (
  <Text>{dictionary.result.investments.TOTAL_INVESTMENTS_BLUE(total)}</Text>
)

const ReserveSection = ({ total }) => (
  <Text>{dictionary.result.investments.TOTAL_INVESTMENTS_RESERVE(total)}</Text>
)

const sectionMap = {
  CRYPTO: CryptoSection,
  NO_CRYPTO: BlueBioSection,
  RESERVE: ReserveSection
}

const getSectionComponent = (section, props) => {
  const Component = sectionMap[section]

  return <Component {...props} />
}

const TotalInvestmentsCard = ({ section, totalInvestments, traditionalRecommendation, cryptoRecommendation }) => {
  const total = currencyFormat(totalInvestments)
  const barProps = {
    traditionalDecimal: (totalInvestments - cryptoRecommendation) / totalInvestments,
    cryptoDecimal: cryptoRecommendation / totalInvestments,
    cryptoRecommendation,
    traditionalRecommendation
  }

  return (
    <ExpansionPanel title={dictionary.result.investments.YOUR_INVESTMENTS} fixed>
      {getSectionComponent(section, { total })}
      <Bar {...barProps} />
    </ExpansionPanel>
  )
}

export default TotalInvestmentsCard
