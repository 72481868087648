import React from 'react'

const Check = ({ size = '24', fill = 'none', color = '#000000', ...rest }) => (
  <svg {...rest} focusable='false' viewBox='0 0 24 24' fill={fill} stroke={color} width={size} height={size}>
    <path d='M12,0.7C5.7,0.7,0.7,5.7,0.7,12S5.7,23.3,12,23.3S23.3,18.3,23.3,12l0,0
      C23.3,5.7,18.3,0.7,12,0.7z M19.4,10l-8.9,7.4c-0.5,0.4-1.2,0.4-1.7,0l-4.1-3.6c-0.5-0.5-0.6-1.3-0.1-1.8c0.5-0.5,1.3-0.6,1.8-0.1
        l3.3,2.9L17.7,8c0.6-0.5,1.4-0.4,1.8,0.2C20,8.7,19.9,9.6,19.4,10L19.4,10z'
    />
  </svg>

)

export default Check
