import styled, { keyframes } from 'styled-components'
import PinIcon from '../Icons/Pin'

const flare1 = keyframes`
  0% {
    opacity: .8;
    transform: scale(0.5) translateX(-0.5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translateX(-0.5px);
  }
`

const flare2 = keyframes`
  0% {
    opacity: .8;
    transform: scale(0.5) translateX(-0.5px);
  }
  100% {
    opacity: 0;
    transform: scale(1.2) translateX(-0.5px);
  }
`

export const BioStagePin = styled(PinIcon)`
  position: relative;
  justify-self: center;
  transform: translateY(-4px);
  grid-column: ${({ stage = 0 }) => stage + 1};

  & .pin-layer-1 {
    opacity: 0.2;
    transform-origin: center center;
    animation: ${flare1} 1.2s ease-in-out infinite both;
  }

  & .pin-layer-2 {
    opacity: 0.2;
    transform-origin: center center;
    animation: ${flare2} 1.2s ease-in-out infinite both;
  }
`

export const ThermometerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, calc(calc(100% - 12.5px) / 5));
  grid-template-rows: 30px 8px;
  grid-column-gap: 2.5px;
  padding: 8px 0;
  margin-bottom: 16px;
`

export const ThermometerStage = styled.div`
  height: 8px;
  width: 100%;
  grid-row: 2;
  background-color: ${({ color }) => color};
  &:nth-child(2) {
    border-radius: 10px 0 0 10px;
  };
  &:last-child {
    border-radius: 0 10px 10px 0;
  };
`
