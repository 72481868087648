import React, { useEffect } from 'react'
import FinancialPlan from './FinancialPlan'
import Investments from './Investments'
import KnowingBio from './KnowingBio'
import FinancesAndAssets from './FinancesAndAssets'

const BioResult = (props) => {
  const registerTrackEvents = () => {
    if (window.MB_AT) {
      window.MB_AT.configureEvent('results', {
        event_type: 'register_bio',
        step: 'results',
        action: 'pageview'
      })
      window.MB_AT.configureEvent('completed', {
        event_type: 'register_bio_complete',
        step: 'finished',
        action: 'success'
      })
      window.MB_AT.logCustomEvent('results')
      window.MB_AT.logCustomEvent('completed')
    }
  }

  useEffect(() => {
    registerTrackEvents()
  }, [])
  
  return (
    <>
      <KnowingBio {...props} />
      <FinancesAndAssets {...props} />
      <FinancialPlan {...props} />
      <Investments {...props} />
    </>
  )
}

export default BioResult
