import styled from 'styled-components'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export default ButtonsContainer
