import React from 'react'
import dictionary from '../../../../../core/dictionary'

import {
  BulletText,
  Check,
  Text,
  TextMutedGray,
  Item,
  BulletContainer,
  IconContainer,
  TextContainer
} from '../../../styles'

const BehavioralProfile = ({ profileType, profileJustification, justifications }) => (
  <>
    <BulletContainer>
      <BulletText>{dictionary.result.knowingBio.BEHAVIORAL_PROFILE}</BulletText>
    </BulletContainer>
    {justifications.map((justification) => (
      <Item key={justification}>
        <IconContainer>
          <Check />
        </IconContainer>
        <Text>{justification}</Text>
      </Item>
    ))}
    <TextContainer>
      <Text>{dictionary.result.knowingBio.CONCLUSION}</Text>
      <TextMutedGray>
        {`${dictionary.result.knowingBio.YOUR_RISK_PROFILE_IS} ${profileType.toLowerCase()}. ${profileJustification}`}
      </TextMutedGray>
    </TextContainer>
  </>
)

export default BehavioralProfile
