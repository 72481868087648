import React from 'react'
import { Formik, Form } from 'formik'
import dictionary from '../../../core/dictionary'
import validationSchema from './validation.schema'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InputMaskedDate from '../../../components/Form/InputMaskedDate'
import ButtonsContainer from '../../../components/ButtonsContainer'
import PhoneInput from '../../../components/Form/PhoneInput'
import {
  AgreementText,
} from './styles'

const BioStep1 = ({ formValues, onContinue, dict }) => {
  const onSubmit = (values) => {
    onContinue(values)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched
      }) => (
        <Form
          data-wa="true"
          data-wa-event-type="register_bio"
          data-wa-event-trigger="load"
          data-wa-step="intro"
          data-wa-action="pageview"
          onSubmit={handleSubmit}>
          <TextField
            id='name'
            value={values.name}
            label={dict.client.name.label}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name ? errors.name : ''}
            margin='normal'
            fullWidth
          />
          <TextField
            id='email'
            type='email'
            value={values.email}
            label={dict.client.email.label}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email ? errors.email : ''}
            margin='normal'
            fullWidth
          />
          <PhoneInput
            id='phone'
            value={values.phone}
            label={dict.client.phone.label}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone ? errors.phone : ''}
            containerStyle={{ paddingTop: 16, paddingBottom: 0 }}
          />
          <TextField
            id='dateOfBirth'
            type='tel'
            value={values.dateOfBirth}
            label={dict.client.dateOfBirth.label}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
            helperText={touched.dateOfBirth ? errors.dateOfBirth : ''}
            margin='normal'
            fullWidth
            InputProps={{
              inputComponent: InputMaskedDate
            }}
          />
          <TextField
            InputLabelProps={{ style: { paddingTop: 8 } }}
            id='gender'
            name='gender'
            select
            value={values.gender}
            label={dict.client.gender.label}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender ? errors.gender : ''}
            fullWidth
            margin='normal'
            style={{ paddingTop: 8 }}
          >
            {dict.client.gender.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
            <MenuItem value={'NOT_DECLARE'}>{dictionary.bio.NOT_DECLARE}</MenuItem>
          </TextField>
          <ButtonsContainer>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              fullWidth
            >
              {dictionary.button.CONTINUE}
            </Button>
          </ButtonsContainer>
          <AgreementText>
            {dictionary.bio.TERMS_AGREEMENT('https://www.mercadobitcoin.com.br/privacidade')}
          </AgreementText>
        </Form>
      )}
    </Formik>
  )
}

export default BioStep1
