import React from 'react'

import dictionary from '../../../../../core/dictionary'
import { currencyFormat, decimalToPercent } from '../../../../../core/utils'

import ExpansionPanel from '../../../../../components/ExpansionPanel'
import PieChart from '../../../../../components/PieChart'

import {
  ItemWrapper,
  Section,
  TextPrimary,
  Check,
  Item,
  TextMutedGray,
  BulletText,
  Text,
  TextBasicGray,
  BulletContainer,
  Wrapper,
  TextWithBoldPrimary,
  TextCTA
} from '../../../styles'

const StrategiesCards = ({ showCryptoSection, bioNumber, bioColor, traditional, crypto }) => {
  return (
    <>
      <Wrapper>
        <TextWithBoldPrimary>{dictionary.result.investments.INVESTMENTS_RECOMMENDATION(bioColor)}</TextWithBoldPrimary>
        <TextBasicGray>{dictionary.result.investments.TRADER}</TextBasicGray>
      </Wrapper>
      <ExpansionPanel title={dictionary.result.investments.TRADITIONAL_INVESTMENTS}>
        <TextWithBoldPrimary>{dictionary.result.investments.TRADITIONAL_RECOMMENDATION(bioColor)}</TextWithBoldPrimary>
        <PieChart data={traditional.portfolio} />
      </ExpansionPanel>
      {showCryptoSection && (
        <ExpansionPanel title={dictionary.result.investments.CRYPTO_INVESTMENTS}>
          <>
            <Text>{dictionary.result.investments.INTEREST_IN_CRYPTO}</Text>
            <ItemWrapper>
              <Item>
                <Check />
                <div>
                  <Text>{dictionary.result.investments.CRYPTO_MAX}</Text>
                  <TextPrimary>{currencyFormat(crypto.total)}</TextPrimary>
                </div>
              </Item>
            </ItemWrapper>
            <Text>{dictionary.result.investments.THIS_VALUE_REPRESENTS}</Text>
            <ItemWrapper>
              <Item>
                <Check />
                <div>
                  <Text>{dictionary.result.investments.OF_YOUR_INVESTMENTS}</Text>
                  <TextPrimary>{decimalToPercent(crypto.investmentsPercentage)}</TextPrimary>
                </div>
              </Item>
              {crypto.assetsPercentage > 0 && (<Item>
                <Check />
                <div>
                  <Text>{dictionary.result.investments.OF_YOUR_TOTAL_ASSETS}</Text>
                  <TextPrimary>{decimalToPercent(crypto.assetsPercentage)}</TextPrimary>
                </div>
              </Item>)}
            </ItemWrapper>
            <Section>
              <TextMutedGray>{dictionary.result.investments.OUR_METODOLOGY}</TextMutedGray>
            </Section>
            <BulletContainer>
              <BulletText>{dictionary.result.investments.WHERE_TO_INVEST}</BulletText>
            </BulletContainer>
            <TextMutedGray>{dictionary.result.investments.EARLY_CRYPTO_INVESTORS}</TextMutedGray>
          </>
          <PieChart data={crypto.portfolio}/>
          <TextCTA>{dictionary.result.investments.UNDERSTAND_DIFFERENCE}</TextCTA>
        </ExpansionPanel>
      )}
    </>
  )
}

export default StrategiesCards
