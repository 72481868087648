import format from 'date-fns/format'
import differenceInYears from 'date-fns/differenceInYears'
import parse from 'date-fns/parse'
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'

function convertISODateToJSDate (date) {
  const [year, month, day] = date.substr(0, 10).split('-')
  return new Date(year, (month - 1), day)
}

export function currencyFormat (value) {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}

export function decimalToPercent (value, numberDecimalPlaces = 1, separator = ',') {
  const valueFormated = (value * 100).toFixed(numberDecimalPlaces)
  return `${valueFormated.replace('.', separator)}%`
}

export function formatDate (date, fmt = 'dd/MM/yyyy') {
  return format(convertISODateToJSDate(date), fmt)
}

export function compact (arr) {
  return arr.filter((value) => value)
}

export function computeAge (birthDate) {
  return differenceInYears(convertISODateToJSDate(new Date().toISOString()), convertISODateToJSDate(birthDate))
}

export function parseDate (date) {
  return format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
}

export function scrollToTop () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export function isCPFValid (cpf) {
  return cpfValidator.isValid(cpf)
}

export function isCNPJValid (cnpj) {
  return cnpjValidator.isValid(cnpj)
}

export function goToMercadoBitcoinRegister () {
  window.open('https://conta.mercadobitcoin.com.br/cadastro/', '_blank')
}

export function goToDashboard () {
  window.open('https://www.mercadobitcoin.com.br/plataforma/dashboard/', '_blank')
}

export const optionsToReduce = (dict) => {
  return dict.reduce((acc, option) => ({
    ...acc,
    [option.value]: option
  }), {})
}
