import React from 'react'
import dictionary from '../../../../../core/dictionary'

import BioThermometer from '../../../../../components/BioThermometer'

import {
  Section,
  TextPrimaryH3,
  NameContainer,
  Title,
  TextWithBoldPrimary
} from '../../../styles'

const Summary = ({ firstName, fullName, bioColor, bioNumber, bioText }) => {
  return (
    <Section>
      <NameContainer>
        <TextPrimaryH3>{fullName}</TextPrimaryH3>
      </NameContainer>
      <Title>{dictionary.result.knowingBio.KNOWING_BIO_RESULTS}</Title>
      <BioThermometer stage={bioNumber} />
      <TextWithBoldPrimary>{dictionary.result.knowingBio.BIO_RESULT({ firstName, bioColor, bioText })}</TextWithBoldPrimary>
    </Section>
  )
}

export default Summary
