import React from 'react'
import MaskedInput from 'react-text-mask'

const getPhoneMask = (userInput = '') => {
  const numbers = userInput.match(/\d/g)
  const numberLength = numbers ? numbers.length : 0

  if (numberLength > 10) {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}

const InputMaskedPhone = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={getPhoneMask}
    />
  )
}

export default InputMaskedPhone
