import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Bio from '../Bio'

const App = () => {
  return (
    <Switch>
      <Route path='/:resultId' component={Bio} />
      <Route path='/' component={Bio} />
      <Redirect from='*' to='/' />
    </Switch>
  )
}

export default App
