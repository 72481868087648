import * as yup from 'yup'
import dictionary from '../../../core/dictionary'

const validationSchema = yup.object().shape({
  personalProperties: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  investmentProperties: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  assets: yup.number().required(dictionary.validation.REQUIRED_FIELD),
  liabilities: yup.number().required(dictionary.validation.REQUIRED_FIELD)
})

export default validationSchema
