import * as yup from 'yup'
import dictionary from '../../../core/dictionary'

const validationSchema = yup.object().shape({
  investments: yup.number()
    .moreThan(0, dictionary.validation.POSITIVE_NUMBER_REQUIRED)
    .required(dictionary.validation.REQUIRED_FIELD),
  investmentLast12Months: yup.string().required(dictionary.validation.REQUIRED_FIELD),
  investmentsInCrypto: yup.number().required(dictionary.validation.REQUIRED_FIELD)
})

export default validationSchema
