import React from 'react'
import CheckboxMui from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Checkbox = ({ onChange, checked, id, name, label }) => {
  const value = name || id || label
  return (
    <FormControlLabel
      control={
        <CheckboxMui
          size='small'
          checked={checked}
          onChange={(e, v) => onChange({ target: { value: v, name: value } })}
          value={value}
        />
      }
      label={label}
    />
  )
}

export default Checkbox
