import React, { memo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SpinnerWrapper } from './SpinnerWrapper'

const SpinnerLoading = memo(({ position = 'fixed', width = '100%' }) => {
  return (
    <SpinnerWrapper width={width} position={position}>
      <CircularProgress />
    </SpinnerWrapper>
  )
})

export default SpinnerLoading
