import React, { useMemo } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { useTheme } from 'styled-components'
import { decimalToPercent } from '../../core/utils'
import {
  Wrapper,
  ChartContainer,
  LegendsContainer,
  Item,
  Color,
  Text
} from './styles'

const PieChart = ({ data }) => {
  const theme = useTheme()
  const formattedData = useMemo(() => data.map(({ label, value, color }) => ({
    id: label,
    color,
    value
  })), [data])

  formattedData.sort((a, b) => b.value - a.value)

  const chartTheme = {
    fontFamily: theme.typography.subtitle1.fontFamily,
    fontSize: 14
  }

  return (
    <Wrapper>
      <ChartContainer>
        <ResponsivePie
          data={formattedData.filter(({ value }) => value > 0)}
          theme={chartTheme}
          colors={({ data }) => data.color}
          innerRadius={0.75}
          margin={{ top: 20, bottom: 20, left: 100, right: 100 }}
          arcLinkLabel={({ value }) => decimalToPercent(value)}
          arcLinkLabelsTextColor={theme.colors.neutral50}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsThickness={2}
          isInteractive={false}
          enableArcLabels={false}
          arcLinkLabelsSkipAngle={12}
        />
      </ChartContainer>
      <LegendsContainer>
        {formattedData.map(({ value, id, color }) => (
          <Item key={id}>
            <Color $color={color}/>
            <div style={{ marginLeft: 8 }}>
              <Text>{id}</Text>
              <Text>{`(${decimalToPercent(value)})`}</Text>
            </div>
          </Item>
        ))}
      </LegendsContainer>
    </Wrapper>
  )
}

export default PieChart
