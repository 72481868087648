import styled from 'styled-components'
import LineCircle from '../../../../../components/LineCircle'

export const DescriptionContainer = styled.div`
  margin-bottom: 8px;
`

export const JustificationsContainer = styled(LineCircle)`
  margin: 8px 0;
`
