import React from 'react'

const Youtube = ({ fill = '#293337', color = '#FFFF', ...rest }) => (
  <svg {...rest} viewBox='0 0 24 24' fill="none" width='24' height='25' xmlns="http://www.w3.org/2000/svg">
    <rect y="0.600098" width="24" height="24" rx="12" fill={fill} />
    <g clipPath="url(#clip0_1186_1962)">
    <path d="M12 17.5691C10.5001 17.5955 9.00058 17.5002 7.5161 17.2843C7.20199 17.1738 6.92055 16.9865 6.69746 16.7393C6.47437 16.4921 6.31672 16.193 6.23891 15.8693C6.06895 14.7812 5.98905 13.681 6.00002 12.5799C5.99194 11.4971 6.07183 10.4154 6.23891 9.34555C6.30701 9.01333 6.46065 8.70463 6.68466 8.45001C6.90866 8.19539 7.19526 8.00366 7.5161 7.89379C8.99988 7.67048 10.4998 7.57213 12 7.59976C13.5003 7.57213 15.0002 7.67048 16.4839 7.89379C16.8035 8.00419 17.0887 8.19625 17.3111 8.45092C17.5336 8.70559 17.6855 9.014 17.7519 9.34555C17.9253 10.4148 18.0082 11.4967 18 12.5799C18.0114 13.6814 17.9284 14.7819 17.7519 15.8693C17.6844 16.2014 17.5322 16.5105 17.31 16.7665C17.0878 17.0225 16.8033 17.2167 16.4839 17.3302C14.9982 17.5311 13.4987 17.611 12 17.5691ZM12 8.4359C10.5834 8.40705 9.16685 8.49309 7.76418 8.69318C7.58436 8.76271 7.42513 8.87674 7.30139 9.02458C7.17765 9.17243 7.09345 9.34925 7.05668 9.53851C6.90328 10.5449 6.82955 11.5618 6.83616 12.5799C6.82883 13.6009 6.90257 14.621 7.05668 15.6304C7.09365 15.8207 7.1778 15.9986 7.30141 16.1479C7.42502 16.2972 7.58415 16.4131 7.76418 16.4849C9.16758 16.677 10.5838 16.76 12 16.733C13.4163 16.76 14.8324 16.677 16.2359 16.4849C16.4168 16.4146 16.5768 16.2991 16.7006 16.1496C16.8245 16 16.908 15.8213 16.9434 15.6304C17.0926 14.6204 17.1663 13.6008 17.1639 12.5799C17.1659 11.562 17.0922 10.5454 16.9434 9.53851C16.9082 9.34859 16.8246 9.17097 16.7007 9.02287C16.5767 8.87476 16.4166 8.76119 16.2359 8.69318C14.8332 8.49309 13.4166 8.40705 12 8.4359Z" fill={color}/>
    <path d="M10.8885 14.6752C10.8144 14.6757 10.7416 14.6566 10.6772 14.6201C10.6134 14.5843 10.5603 14.5324 10.5231 14.4694C10.486 14.4065 10.4662 14.3348 10.4658 14.2617V10.908C10.468 10.8358 10.4886 10.7653 10.5258 10.7034C10.5629 10.6414 10.6153 10.59 10.678 10.5541C10.7407 10.5181 10.8115 10.4988 10.8837 10.4981C10.956 10.4973 11.0272 10.515 11.0906 10.5496L13.9942 12.1943C14.0593 12.2299 14.1137 12.2824 14.1516 12.3463C14.1895 12.4101 14.2095 12.483 14.2095 12.5573C14.2095 12.6315 14.1895 12.7044 14.1516 12.7683C14.1137 12.8322 14.0593 12.8846 13.9942 12.9202L11.0906 14.6201C11.0289 14.6551 10.9594 14.6741 10.8885 14.6752ZM11.302 11.6339V13.5358L12.9467 12.617L11.302 11.6339Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_1186_1962">
    <rect width="12" height="9.96937" fill={color} transform="translate(6 7.6001)"/>
    </clipPath>
    </defs>
  </svg>
)

export default Youtube
