import React from 'react'
import dictionary from '../../../../core/dictionary'
import { useTheme } from 'styled-components'

import Finances from './Finances'
import Assets from './Assets'
import FinancialSituation from './FinancialSituation'

import { Title, TitleContainer, Wrapper } from '../../styles'

const FinancesAndAssets = ({ data: { payload, result } }) => {
  const { riskProfile } = result
  const { bioFeedBacks, bioResult } = riskProfile
  const {
    personalIncome,
    spouseIncome,
    otherIncome,
    expenses,
    investments,
    investmentsInCrypto,
    investmentProperties,
    personalProperties,
    assets,
    otherAssets,
    liabilities
  } = payload

  const theme = useTheme()
  const totalIncome = personalIncome + spouseIncome + otherIncome
  const totalInvestments = investments + investmentsInCrypto
  const savingsCapacity = totalIncome ? (totalIncome - expenses) / totalIncome : 0
  const yearsCoveredByTotalInvestment = expenses ? totalInvestments / expenses / 12 : 0
  const totalAssets = investmentProperties + personalProperties + assets + otherAssets + totalInvestments
  const balance = Math.max(0, totalIncome - expenses)

  const chartData = [
    {
      label: dictionary.result.financesAndAssets.INVESTMENTS,
      value: totalInvestments,
      color: theme.colors.chart5
    },
    {
      label: dictionary.result.financesAndAssets.ASSETS,
      value: assets,
      color: theme.colors.negative30
    },
    {
      label: dictionary.result.financesAndAssets.OTHER_ASSETS,
      value: otherAssets,
      color: theme.colors.chart6
    },
    {
      label: dictionary.result.financesAndAssets.REAL_STATE,
      value: investmentProperties,
      color: theme.colors.chart3
    },
    {
      label: dictionary.result.financesAndAssets.PERSONAL_USE,
      value: personalProperties,
      color: theme.colors.chart1
    }
  ]

  return (
    <>
      <TitleContainer>
        <Title>{dictionary.result.financesAndAssets.YOUR_FINANCES_AND_ASSETS}</Title>
      </TitleContainer>
      <Wrapper>
        <Finances totalIncome={totalIncome} expenses={expenses} balance={balance}/>
        <Assets chartData={chartData} totalAssets={totalAssets} liabilities={liabilities} />
        <FinancialSituation
          justifications={bioFeedBacks.financialCapacity}
          showValues={Math.floor(bioResult.financialCapacity.Res) > 19}
          savingsCapacity={savingsCapacity}
          coverageYears={yearsCoveredByTotalInvestment}
        />
      </Wrapper>
    </>
  )
}

export default FinancesAndAssets
