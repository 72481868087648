import React from 'react'
import ExpansionPanel from '../../../../../components/ExpansionPanel'
import dictionary from '../../../../../core/dictionary'
import { decimalToPercent } from '../../../../../core/utils'

import { TextMutedGray, Text } from '../../../styles'
import { JustificationsContainer, DescriptionContainer } from './styles'

const FinancialSituation = ({
  justifications,
  showValues,
  savingsCapacity,
  coverageYears
}) => {
  const firstJustificationMarginBottom = justifications.feedbackOptions[1] ? 16 : 0
  return (
    <ExpansionPanel title={dictionary.result.financesAndAssets.YOUR_CURRENT_FINANCIAL_SITUATION} fixed>
      <DescriptionContainer>
        <TextMutedGray>{justifications.A}</TextMutedGray>
      </DescriptionContainer>
      <JustificationsContainer>
        {justifications.feedbackOptions[0] && (
          <>
            <TextMutedGray>{justifications.B}</TextMutedGray>
            {showValues && (
              <Text style={{ marginBottom: firstJustificationMarginBottom }}>
                {decimalToPercent(savingsCapacity)} {dictionary.result.financesAndAssets.OF_YOUR_EARNS}
              </Text>
            )}
          </>
        )}
        {justifications.feedbackOptions[1] && (
          <>
            <TextMutedGray>{justifications.C}</TextMutedGray>
            {showValues && (
              <Text>
                {Math.floor(coverageYears)} {dictionary.result.financesAndAssets.OF_YOUR_EXPENSES}
              </Text>
            )}
          </>
        )}
      </JustificationsContainer>
    </ExpansionPanel>
  )
}

export default FinancialSituation
