import React from 'react'

import dictionary from '../../../core/dictionary'

import Logo from '../../Images/logo.svg'
import PolygonImage from '../../Images/polygon.png'
import FacebookIcon from '../../Icons/Facebook'
import YoutubeIcon from '../../Icons/Youtube'
import InstagramIcon from '../../Icons/Instagram'
import LinkedinIcon from '../../Icons/Linkedin'
import TwitterIcon from '../../Icons/Twitter'

import {
  FooterWrapper,
  FooterContainer,
  LogoWrapper,
  TextContainer,
  Text,
  TextSubContainer,
  Divider,
  SocialWrapper,
  Polygon
} from './styles'

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <LogoWrapper>
        <img src={Logo} alt={dictionary.alt.LOGO_MERCADO_BITCOIN} />
      </LogoWrapper>
      <TextContainer>
        <TextSubContainer>
          <Text>{dictionary.bio.MERCADO_BITCOIN}</Text>
        </TextSubContainer>
        <Divider>–</Divider>
        <Text>{dictionary.bio.CNPJ}</Text>
      </TextContainer>
      <SocialWrapper>
        <a href='https://www.facebook.com/MercadoBitcoin/' target='_blank' rel='noopener noreferrer' aria-label={dictionary.alt.ICON_FACEBOOK}>
          <FacebookIcon />
        </a>
        <a href='https://www.instagram.com/mercadobitcoin' target='_blank' rel='noopener noreferrer' aria-label={dictionary.alt.ICON_INSTAGRAM}>
          <InstagramIcon />
        </a>
        <a href='https://br.linkedin.com/company/mercado-bitcoin' target='_blank' rel='noopener noreferrer' aria-label={dictionary.alt.ICON_LINKEDIN}>
          <LinkedinIcon />
        </a>
        <a href='https://twitter.com/MercadoBitcoin' target='_blank' rel='noopener noreferrer' aria-label={dictionary.alt.ICON_TWITTER}>
          <TwitterIcon />
        </a>
        <a href='https://www.youtube.com/channel/UCiciruB9iiUmKT8C6qk8T4w?view_as=subscriber' target='_blank' rel='noopener noreferrer' aria-label={dictionary.alt.ICON_YOUTUBE}>
          <YoutubeIcon />
        </a>
      </SocialWrapper>
    </FooterContainer>
    <Polygon src={PolygonImage} alt={dictionary.alt.ILLUSTRATION_POLYGON} />
  </FooterWrapper>
)

export default Footer
