import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import SliderMui from '@material-ui/core/Slider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    color: theme.colors.primary30,
    height: 8
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: theme.colors.white,
    boxShadow: '1px 1px 6px 0px #00000029',
    border: '1px solid currentColor',
    marginTop: -4,
    marginLeft: -8,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    backgroundColor: theme.colors.neutral20,
    height: 8,
    borderRadius: 4
  },
  mark: {
    backgroundColor: 'unset'
  },
  markLabel: {
    top: 28,
    color: theme.colors.neutral30,
    fontSize: theme.typography.caption
  }
})

const SliderContainer = styled.div`
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
`
const SliderWrapper = styled.div`
  width: 100%;
  order: -1;
  flex: 1 0 100%;
`

const LeftLabel = styled(Typography).attrs({
  variant: 'body1'
})`
  margin-right: 32px;
`
const RightLabel = styled(Typography).attrs({
  variant: 'body1'
})`
  margin-left: 32px;
  flex: auto;
  text-align: right;
`

const Slider = ({ id, label, leftLabel, rightLabel, onChange, error, helperText, margin = 'none', ...props }) => {
  const inputLabelId = label && id ? `${id}-label` : undefined
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined
  return (
    <FormControl error={error} margin={margin} fullWidth>
      {label && (
        <InputLabel htmlFor={id} id={inputLabelId} shrink>
          {label}
        </InputLabel>
      )}
      <SliderContainer>
        {leftLabel && (
          <LeftLabel>
            {leftLabel}
          </LeftLabel>
        )}
        <SliderWrapper>
          <SliderMui
            id={id}
            onChange={(e, value) => onChange({ target: { value, name: id } })}
            component='div'
            {...props}
          />
        </SliderWrapper>
        {rightLabel && (
          <RightLabel>
            {rightLabel}
          </RightLabel>
        )}
      </SliderContainer>
      {helperText && (
        <FormHelperText id={helperTextId}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default withStyles(styles)(Slider)
